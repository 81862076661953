import "./App.css";
import Main from "./Component/Main";
import About from "./Component/About";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacy from "./Component/Privacy";
import Contact from "./Component/Contact";
import Terms from "./Component/Terms";
import Advertise from "./Component/Advertise";
import Decor from "./Component/Decor";
import Garden from "./Component/Garden";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />{" "}
        <Route path="/garden" element={<Garden />} />{" "}
        <Route path="/decore" element={<Decor />} />{" "}
        <Route path="/advertise" element={<Advertise />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </Router>
  );
}

export default App;
