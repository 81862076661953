import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/mainTwo.css";
import { Link } from "react-router-dom";

function Main() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="main1_box">
          <ul>
            <li>
              <div class="stats-nav__image"></div>
              <span class="stats-nav__number">385 Million</span>
              <span class="stats-nav__text">
                <span class="stats-nav__text-words">Annual Readers</span>
              </span>
            </li>
            <li>
              <div class="stats-nav__image"></div>
              <span class="stats-nav__number">1,700+</span>
              <span class="stats-nav__text">
                <span class="stats-nav__text-words">Products Tested</span>
              </span>
            </li>
            <li>
              <div class="stats-nav__image"></div>
              <span class="stats-nav__number">19,000+</span>
              <span class="stats-nav__text">
                <span class="stats-nav__text-words">Original Photos</span>
              </span>
            </li>
            <li>
              <div class="stats-nav__image"></div>
              <span class="stats-nav__number">2,000</span>
              <span class="stats-nav__text">
                <span class="stats-nav__text-words">How-To Projects</span>
              </span>
            </li>
          </ul>
        </div>

        <div className="main2_box">
          <div className="main2_box_left">
            <div className="main2_box_left_inner">
              <span class="card__title">
                <span class="card__title-text ">
                  Cyber Monday Is Here! Shop Our Favorite Deals
                </span>
              </span>
            </div>
          </div>

          <div className="main2_box_right">
            <div class="socialvideo-showcase__background">
              <div className="socialvideo-showcase__media">
                <img
                  alt=""
                  src="https://cdn.jwplayer.com/v2/media/Rt8hzNPo/poster.jpg?width=720"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="main3_box">
          <span className="main3_box_title">
            <h2>Editors' Picks</h2>
          </span>
          <div
            id="mntl-three-post__inner_1-0"
            className="comp mntl-three-post__inner mntl-universal-card-list mntl-document-card-list mntl-card-list mntl-block"
            data-chunk={36}
          >
            <a
              id="mntl-card-list-items_1-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={8739496}
              data-tax-levels=""
              href="https://www.thespruce.com/best-black-friday-vacuum-deals-2024-8739496"
              data-ordinal={1}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/PLLblUH-Q-f8bv96hZMBjBVVKrk=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/pet-hair-vacuums-test-dyson-cyclone-v10-animal-dyson-rmarek-310-1-49c0b3fc71644854a679c612a13c96b2.jpeg"
                      width={728}
                      height={484}
                      alt="Dyson Hair Vacuum vacuuming pet hair"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/PLLblUH-Q-f8bv96hZMBjBVVKrk=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/pet-hair-vacuums-test-dyson-cyclone-v10-animal-dyson-rmarek-310-1-49c0b3fc71644854a679c612a13c96b2.jpeg"
                    />
                    <noscript>
                      &lt;img
                      src="https://www.thespruce.com/thmb/PLLblUH-Q-f8bv96hZMBjBVVKrk=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/pet-hair-vacuums-test-dyson-cyclone-v10-animal-dyson-rmarek-310-1-49c0b3fc71644854a679c612a13c96b2.jpeg"
                      width="728" height="484" class="img--noscript card__img
                      universal-image__image" alt="Dyson Hair Vacuum vacuuming
                      pet hair" /&gt;
                    </noscript>
                  </div>
                </div>
              </div>
              <div className="card__content " data-tag="Sales & Deals">
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    The Best Vacuum Deals for Cyber Monday—Shop Shark, Dyson,
                    and More
                  </span>
                </span>
              </div>
            </a>
            <a
              id="mntl-card-list-items_2-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={8745265}
              data-tax-levels=""
              href="https://www.thespruce.com/black-friday-gifts-under-50-8745265"
              data-ordinal={2}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/msWzBfwIkrQQA0qgZPSY7j0o3No=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Screenshot2024-11-28at10.28.22AM-624a32d90dbb4c17bc0caa1c385328a6.png"
                      width={728}
                      height={484}
                      alt="Multicolored coupe glasses"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/msWzBfwIkrQQA0qgZPSY7j0o3No=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Screenshot2024-11-28at10.28.22AM-624a32d90dbb4c17bc0caa1c385328a6.png"
                    />
                    <noscript>
                      &lt;img
                      src="https://www.thespruce.com/thmb/msWzBfwIkrQQA0qgZPSY7j0o3No=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Screenshot2024-11-28at10.28.22AM-624a32d90dbb4c17bc0caa1c385328a6.png"
                      width="728" height="484" class="img--noscript card__img
                      universal-image__image" alt="Multicolored coupe glasses"
                      /&gt;
                    </noscript>
                  </div>
                </div>
              </div>
              <div className="card__content " data-tag="Sales & Deals">
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    I’m Nailing Gifting Season With These Under $50 Cyber Monday
                    Scores
                  </span>
                </span>
              </div>
            </a>
            <a
              id="mntl-card-list-items_3-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={8747059}
              data-tax-levels=""
              href="https://www.thespruce.com/black-friday-designer-approved-deals-under-100-8747059"
              data-ordinal={3}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/_9y1_P_HhGx-0OotJRB_TiyCXC4=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/holly_mini_cocotte_g1-d50566fc78234cc8ad78402afb3f5548.jpg"
                      width={728}
                      height={484}
                      alt="Mini crocette from Le Creuset's Holly collection"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/_9y1_P_HhGx-0OotJRB_TiyCXC4=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/holly_mini_cocotte_g1-d50566fc78234cc8ad78402afb3f5548.jpg"
                    />
                    <noscript>
                      &lt;img
                      src="https://www.thespruce.com/thmb/_9y1_P_HhGx-0OotJRB_TiyCXC4=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/holly_mini_cocotte_g1-d50566fc78234cc8ad78402afb3f5548.jpg"
                      width="728" height="484" class="img--noscript card__img
                      universal-image__image" alt="Mini crocette from Le
                      Creuset&amp;#39;s Holly collection" /&gt;
                    </noscript>
                  </div>
                </div>
              </div>
              <div className="card__content " data-tag="Sales & Deals">
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    The Best Designer-Approved, Under-$100 Cyber Monday Picks
                  </span>
                </span>
              </div>
            </a>
          </div>
        </div>

        <div
          id="homepage-overview_1-0"
          className="comp homepage-overview div mntl-block"
        >
          <div
            id="homepage-mission_1-0"
            className="comp homepage-mission"
            data-tracking-container="true"
          >
            <a
              href="/about-us-4776800"
              rel="nocaes"
              title="Open About Us page"
              className="homepage-mission__link-wrapper"
            >
              <div className="homepage-mission__background mntl-universal-image universal-image__container">
                <div
                  className="img-placeholder"
                  style={{ paddingBottom: "75.0%" }}
                >
                  <img
                    data-src="https://www.thespruce.com/thmb/-T3tN20LB5DgvcgLYDHeSan7G5k=/1260x946/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/homepage-mission-background-34a5a4b1ba1f4e7fbf9f3c77be5c3e5e.jpg"
                    width={1260}
                    height={946}
                    alt=""
                    className="universal-image__image lazyloadwait ls-is-cached lazyloaded"
                    src="https://www.thespruce.com/thmb/-T3tN20LB5DgvcgLYDHeSan7G5k=/1260x946/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/homepage-mission-background-34a5a4b1ba1f4e7fbf9f3c77be5c3e5e.jpg"
                  />
                  <noscript>
                    &lt;img
                    src="https://www.thespruce.com/thmb/-T3tN20LB5DgvcgLYDHeSan7G5k=/1260x946/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/homepage-mission-background-34a5a4b1ba1f4e7fbf9f3c77be5c3e5e.jpg"
                    width="1260" height="946" class="img--noscript
                    universal-image__image" alt="" /&gt;
                  </noscript>
                </div>
              </div>
              <div className="homepage-mission__info">
                <h3 className="homepage-mission__title">Our Mission</h3>
                <span className="homepage-mission__description">
                  Our goal is to empower you to achieve your home improvement
                  dreams, from the <em>aha</em> moment to project completion.
                  Whatever the task, The Spruce can show you how.
                </span>
                <img
                  alt=""
                  src="/head/main1.png"
                  className="homepage-mission__signature"
                />
              </div>
            </a>
          </div>
          <div
            id="homepage-team_1-0"
            className="comp homepage-team l-container-med"
            data-tracking-container="true"
          >
            {" "}
            <div className="homepage-team__wrapper">
              <h3 className="homepage-team__title">Meet Our Team</h3>
              <span className="homepage-team__description">
                Say hello to the editors who love all things home and garden as
                much as you do.
              </span>
              <div className="loc homepage-team__members">
                <a
                  href="https://www.thespruce.com/aliyah-rodriguez-6561125"
                  rel="nocaes"
                  className="homepage-team__member"
                >
                  {" "}
                  <div className="homepage-team__member-media">
                    <div className="loc member-image">
                      <div
                        className="img-placeholder"
                        style={{ paddingBottom: "100.0%" }}
                      >
                        <img
                          data-src="https://www.thespruce.com/thmb/3Fd3RhnaZHHGQJ3PJZqROLvdvt4=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/aliyah_square1-2447311c85874335a453af0e8a35d180.jpg"
                          width={220}
                          height={220}
                          alt="Aliyah Rodriguez for The Spruce "
                          className="homepage-team__member-img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                          src="https://www.thespruce.com/thmb/3Fd3RhnaZHHGQJ3PJZqROLvdvt4=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/aliyah_square1-2447311c85874335a453af0e8a35d180.jpg"
                        />
                        <noscript>
                          &lt;img
                          src="https://www.thespruce.com/thmb/3Fd3RhnaZHHGQJ3PJZqROLvdvt4=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/aliyah_square1-2447311c85874335a453af0e8a35d180.jpg"
                          width="220" height="220" class="img--noscript
                          homepage-team__member-img universal-image__image"
                          alt="Aliyah Rodriguez for The Spruce " /&gt;
                        </noscript>
                      </div>
                    </div>{" "}
                  </div>
                  <span className="homepage-team__member-name">
                    Aliyah Rodriguez
                  </span>
                  <span className="homepage-team__member-title">
                    Associate Editor
                  </span>
                </a>
                <a
                  href="https://www.thespruce.com/jane-kim-5211262"
                  rel="nocaes"
                  className="homepage-team__member"
                >
                  {" "}
                  <div className="homepage-team__member-media">
                    <div className="loc member-image">
                      <div
                        className="img-placeholder"
                        style={{ paddingBottom: "100.0%" }}
                      >
                        <img
                          data-src="https://www.thespruce.com/thmb/pIEMLHGzW0gyZDqRweqdcmw-J-8=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Jane_square-39b0eab0ffb44ebba71dfdb0d7656242.png"
                          width={220}
                          height={220}
                          alt="Jane Kim"
                          className="homepage-team__member-img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                          src="https://www.thespruce.com/thmb/pIEMLHGzW0gyZDqRweqdcmw-J-8=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Jane_square-39b0eab0ffb44ebba71dfdb0d7656242.png"
                        />
                        <noscript>
                          &lt;img
                          src="https://www.thespruce.com/thmb/pIEMLHGzW0gyZDqRweqdcmw-J-8=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Jane_square-39b0eab0ffb44ebba71dfdb0d7656242.png"
                          width="220" height="220" class="img--noscript
                          homepage-team__member-img universal-image__image"
                          alt="Jane Kim" /&gt;
                        </noscript>
                      </div>
                    </div>{" "}
                  </div>
                  <span className="homepage-team__member-name">Jane Kim</span>
                  <span className="homepage-team__member-title">
                    Associate Editor
                  </span>
                </a>
                <a
                  href="https://www.thespruce.com/kate-mckenna-5080351"
                  rel="nocaes"
                  className="homepage-team__member"
                >
                  {" "}
                  <div className="homepage-team__member-media">
                    <div className="loc member-image">
                      <div
                        className="img-placeholder"
                        style={{ paddingBottom: "100.0%" }}
                      >
                        <img
                          data-src="https://www.thespruce.com/thmb/qt-Op3ohyJlgMn8FUj0XzwKGj5E=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Kate_square-67a5345fbb614ff29a2b0fdf294dfd33.png"
                          width={220}
                          height={220}
                          alt="Kate McKenna"
                          className="homepage-team__member-img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                          src="https://www.thespruce.com/thmb/qt-Op3ohyJlgMn8FUj0XzwKGj5E=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Kate_square-67a5345fbb614ff29a2b0fdf294dfd33.png"
                        />
                        <noscript>
                          &lt;img
                          src="https://www.thespruce.com/thmb/qt-Op3ohyJlgMn8FUj0XzwKGj5E=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Kate_square-67a5345fbb614ff29a2b0fdf294dfd33.png"
                          width="220" height="220" class="img--noscript
                          homepage-team__member-img universal-image__image"
                          alt="Kate McKenna" /&gt;
                        </noscript>
                      </div>
                    </div>{" "}
                  </div>
                  <span className="homepage-team__member-name">
                    Kate McKenna
                  </span>
                  <span className="homepage-team__member-title">
                    Senior Editor
                  </span>
                </a>
                <a
                  href="https://www.thespruce.com/natalia-gonzalez-blanco-serrano-8721253"
                  rel="nocaes"
                  className="homepage-team__member"
                >
                  {" "}
                  <div className="homepage-team__member-media">
                    <div className="loc member-image">
                      <div
                        className="img-placeholder"
                        style={{ paddingBottom: "100.0%" }}
                      >
                        <img
                          data-src="https://www.thespruce.com/thmb/qmaIhcbgOsOpWj9M8IRIkCC1Y4E=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Natalia_square-3de5909ee86d4060b86c80b09ccb5296.png"
                          width={220}
                          height={220}
                          alt="Natalia Gonazales Blanco Serrano"
                          className="homepage-team__member-img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                          src="https://www.thespruce.com/thmb/qmaIhcbgOsOpWj9M8IRIkCC1Y4E=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Natalia_square-3de5909ee86d4060b86c80b09ccb5296.png"
                        />
                        <noscript>
                          &lt;img
                          src="https://www.thespruce.com/thmb/qmaIhcbgOsOpWj9M8IRIkCC1Y4E=/220x220/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Natalia_square-3de5909ee86d4060b86c80b09ccb5296.png"
                          width="220" height="220" class="img--noscript
                          homepage-team__member-img universal-image__image"
                          alt="Natalia Gonazales Blanco Serrano" /&gt;
                        </noscript>
                      </div>
                    </div>{" "}
                  </div>
                  <span className="homepage-team__member-name">
                    Natalia González
                  </span>
                  <span className="homepage-team__member-title">
                    Associate Editor
                  </span>
                </a>
              </div>
              <a
                href="/about-us-4776800#toc-our-team"
                rel="nocaes"
                className="homepage-team__link"
              >
                {" "}
                Meet the rest of the team
              </a>
              <div className="homepage-team__more">
                <a
                  href="https://abcnews.go.com/GMA/GMA3/video/expert-talks-indoor-gardening-87187855"
                  rel="noopener nocaes"
                  className="homepage-team__more-link"
                >
                  {" "}
                  See Mélanie Teach Us About Container Gardening on GMA3
                </a>{" "}
              </div>
            </div>
          </div>
        </div>

        <section
          id="three-post_1-0"
          className="comp three-post mntl-three-post mntl-block"
          data-tracking-container="true"
        >
          <a
            href="https://www.thespruce.com/sales-and-deals-5070016"
            rel="nocaes"
            id="mntl-three-post__title_2-0"
            className="mntl-three-post__title mntl-section-title type--giraffe-link is-link"
            data-tracking-container="true"
            style={{ textDecoration: "none" }}
          >
            <h2
              id="mntl-section-title__heading_2-0"
              className="comp mntl-section-title__heading mntl-text-block type--giraffe-link"
            >
              Cyber Monday Gift Deals
            </h2>
          </a>
          <div
            id="mntl-three-post__inner_2-0"
            className="comp mntl-three-post__inner mntl-universal-card-list mntl-document-card-list mntl-card-list mntl-block"
            data-chunk={36}
          >
            <a
              id="mntl-card-list-items_4-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={8733955}
              data-tax-levels=""
              href="https://www.thespruce.com/holiday-gift-ideas-2024-8733955"
              data-ordinal={1}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/cc39jkl9Gy4xkBG-IyG3_S2L8Y8=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-detail-christmas-gifts-eb6414690beb4eb481cc8ebb5de7b218.jpg"
                      width={728}
                      height={484}
                      alt="Person add a pink ornament on an artificial Christmas tree"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/cc39jkl9Gy4xkBG-IyG3_S2L8Y8=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-detail-christmas-gifts-eb6414690beb4eb481cc8ebb5de7b218.jpg"
                    />
                    <noscript>
                      &lt;img
                      src="https://www.thespruce.com/thmb/cc39jkl9Gy4xkBG-IyG3_S2L8Y8=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-detail-christmas-gifts-eb6414690beb4eb481cc8ebb5de7b218.jpg"
                      width="728" height="484" class="img--noscript card__img
                      universal-image__image" alt="Person add a pink ornament on
                      an artificial Christmas tree" /&gt;
                    </noscript>
                  </div>
                </div>
              </div>
              <div
                className="card__content "
                data-tag="Gifts for Family & Friends"
              >
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    Cyber Monday Has Loads of Sales on Gifts: Our Favs
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Staff Author"
                  data-author="Staff Author"
                />
              </div>
            </a>
            <a
              id="mntl-card-list-items_5-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={4157464}
              data-tax-levels=""
              href="https://www.thespruce.com/best-chocolate-gifts-4157464"
              data-ordinal={2}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/CndheHL2BvhVrQ7FQg28GauJcCo=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-tier-3-primary-best-chocolate-gifts-ebrockob-001-1-b4ae7300b9fb4fba8c15a3b3acd204d6.jpeg"
                      width={728}
                      height={484}
                      alt="Best Chocolate Gifts"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/CndheHL2BvhVrQ7FQg28GauJcCo=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-tier-3-primary-best-chocolate-gifts-ebrockob-001-1-b4ae7300b9fb4fba8c15a3b3acd204d6.jpeg"
                    />
                    <noscript>
                      &lt;img
                      src="https://www.thespruce.com/thmb/CndheHL2BvhVrQ7FQg28GauJcCo=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-tier-3-primary-best-chocolate-gifts-ebrockob-001-1-b4ae7300b9fb4fba8c15a3b3acd204d6.jpeg"
                      width="728" height="484" class="img--noscript card__img
                      universal-image__image" alt="Best Chocolate Gifts" /&gt;
                    </noscript>
                  </div>
                </div>
              </div>
              <div
                className="card__content "
                data-tag="Gifts for Family & Friends"
              >
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    Our Favorite Decadent Chocolate Gifts for Your Lucky Loved
                    Ones
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Julia Fields"
                  data-author="Patrice Williams"
                />
              </div>
            </a>
            <a
              id="mntl-card-list-items_6-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={1666064}
              data-tax-levels=""
              href="https://www.thespruce.com/buy-gift-baskets-online-1666064"
              data-ordinal={3}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/NyqOqPPKfgQr9X_MZvel9G5YVBw=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-gourmet-gift-baskets-classic-white-wine-gift-basket-jkim-0046-1-b1323e2f9dfe440f9f9cdcca3f29edaa.jpeg"
                      width={728}
                      height={484}
                      alt="The Gourmet Gift Baskets White Wine Gift Basket is partially opened with the box it arrives in behind it."
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/NyqOqPPKfgQr9X_MZvel9G5YVBw=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-gourmet-gift-baskets-classic-white-wine-gift-basket-jkim-0046-1-b1323e2f9dfe440f9f9cdcca3f29edaa.jpeg"
                    />
                    <noscript>
                      &lt;img
                      src="https://www.thespruce.com/thmb/NyqOqPPKfgQr9X_MZvel9G5YVBw=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-gourmet-gift-baskets-classic-white-wine-gift-basket-jkim-0046-1-b1323e2f9dfe440f9f9cdcca3f29edaa.jpeg"
                      width="728" height="484" class="img--noscript card__img
                      universal-image__image" alt="The Gourmet Gift Baskets
                      White Wine Gift Basket is partially opened with the box it
                      arrives in behind it." /&gt;
                    </noscript>
                  </div>
                </div>
              </div>
              <div
                className="card__content "
                data-tag="Gifts for Family & Friends"
              >
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    Make Their Day With One of These Tested (and Loved!) Gift
                    Baskets
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Julia Fields"
                  data-author="Camryn Rabideau"
                />
              </div>
            </a>
            <a
              id="mntl-card-list-items_7-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={8742931}
              data-tax-levels=""
              href="https://www.thespruce.com/retro-holiday-decor-8742931"
              data-ordinal={4}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.6%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/NwkZiseB9dVUcIzm6fHZBG_tffI=/1416x944/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/jollysantatablerunner-cbdbaff79ba84848b0fe81303352b5e6.jpg"
                      width={1416}
                      height={944}
                      alt="santa table runner"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/NwkZiseB9dVUcIzm6fHZBG_tffI=/1416x944/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/jollysantatablerunner-cbdbaff79ba84848b0fe81303352b5e6.jpg"
                    />
                    <noscript>
                      &lt;img
                      src="https://www.thespruce.com/thmb/NwkZiseB9dVUcIzm6fHZBG_tffI=/1416x944/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/jollysantatablerunner-cbdbaff79ba84848b0fe81303352b5e6.jpg"
                      width="1416" height="944" class="img--noscript card__img
                      universal-image__image" alt="santa table runner" /&gt;
                    </noscript>
                  </div>
                </div>
              </div>
              <div className="card__content " data-tag="Brands & Collections">
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    This Retro-Inspired Holiday Decor From Amazon Is Full of
                    Nostalgia
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Sarah Lyon"
                  data-author="Sarah Lyon"
                />
              </div>
            </a>
          </div>
        </section>

        <div id="commerce-spotlight_1-0" className="comp commerce-spotlight">
          {" "}
          <div className="commerce-spotlight__content l-container-med">
            <svg className="icon 3-stars commerce-spotlight__3-stars">
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="#3-stars"
                href="#3-stars"
              />
            </svg>
            <svg className="icon 1-star commerce-spotlight__1-star">
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="#1-star"
                href="#1-star"
              />
            </svg>
            <div className="commerce-spotlight__intro">
              <h3 className="commerce-spotlight__heading">
                <span className="commerce-spotlight__heading-1">
                  We Test Products
                </span>
                <span className="commerce-spotlight__heading-2">
                  So You Don’t Have To
                </span>
              </h3>
              <p className="commerce-spotlight__description">
                We vacuum cereal, sleep on dozens of mattresses, and ask our
                kids to jump on furniture. Our editors and experts are
                constantly testing products in our lab and at home to give you
                product recommendations you can trust. We do the research and
                give honest feedback—all so you can live your best life.{" "}
                <a
                  href="/commerce-guidelines-and-mission-4157991"
                  rel="nocaes"
                  className="commerce-spotlight__learn-more-link"
                >
                  Learn more about our process.
                </a>
              </p>
            </div>
            <div
              id="commerce-spotlight__video_1-0"
              className="comp commerce-spotlight__video video--silent-loop div mntl-block"
              inert="true"
            >
              <div
                id="video-player--jwplayer_2-0"
                className="jwplayer jw-reset jw-state-idle jw-stretch-uniform jw-flag-aspect-mode jw-flag-controls-hidden jw-skin-mantle jw-keep-thumbnail jw-breakpoint-3"
                tabIndex={0}
                aria-label="Video Player"
                style={{ width: "100%" }}
              >
                <div
                  className="jw-aspect jw-reset"
                  style={{ paddingTop: "75%" }}
                />
                <div className="jw-wrapper jw-reset">
                  <div
                    style={{
                      opacity: 0,
                      visibility: "hidden",
                      overflow: "hidden",
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        overflow: "auto",
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div style={{ height: 1, width: 598 }} />
                    </div>
                    <div
                      className="jw-contract-trigger"
                      style={{
                        overflow: "auto",
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <div className="jw-top jw-reset" />
                  <div
                    className="jw-aspect jw-reset"
                    style={{ paddingTop: "75%" }}
                  />
                  <div className="jw-media jw-reset">
                    <video
                      className="jw-video jw-reset"
                      tabIndex={-1}
                      disableremoteplayback=""
                      webkit-playsinline=""
                      playsInline=""
                      src="blob:https://www.thespruce.com/d2cb2f60-aae4-41f5-a7f8-e152fc12c1cb"
                    />
                  </div>
                  <div
                    className="jw-preview jw-reset"
                    style={{
                      backgroundImage:
                        'url("https://cdn.jwplayer.com/v2/media/UoX1gxt9/poster.jpg?width=640")',
                      backgroundSize: "cover",
                    }}
                  />
                  <div
                    className="jw-captions jw-reset"
                    style={{ fontSize: 24 }}
                  />
                  <div
                    className="jw-title jw-reset-text"
                    dir="auto"
                    style={{ display: "none" }}
                  >
                    <div className="jw-title-primary jw-reset-text" />
                    <div className="jw-title-secondary jw-reset-text" />
                  </div>
                  <div className="jw-overlays jw-reset">
                    <div
                      id="video-player--jwplayer_2-0_jwpsrv"
                      className="jw-plugin jw-reset"
                    />
                  </div>
                  <div className="jw-hidden-accessibility">
                    <span className="jw-time-update" aria-live="assertive" />
                    <span className="jw-volume-update" aria-live="assertive" />
                  </div>
                </div>
                <div
                  className="afs_ads ad-placement"
                  style={{
                    width: 1,
                    height: 1,
                    position: "absolute",
                    background: "transparent",
                  }}
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="commerce-spotlight__overview">
              <h4 className="commerce-spotlight__overview-heading">
                The Spruce Lab
              </h4>
              <div className="commerce-spotlight__overview-entries">
                <div className="commerce-spotlight__overview-entry">
                  <p className="commerce-spotlight__overview-number">10,000</p>
                  <p className="commerce-spotlight__overview-text">
                    Square Foot Lab
                  </p>
                </div>
                <div className="commerce-spotlight__overview-entry">
                  <p className="commerce-spotlight__overview-number">100s</p>
                  <p className="commerce-spotlight__overview-text">
                    of Products Tested Monthly
                  </p>
                </div>
                <div className="commerce-spotlight__overview-entry">
                  <p className="commerce-spotlight__overview-number">
                    100,000+
                  </p>
                  <p className="commerce-spotlight__overview-text">
                    Hours Spent Testing Annually
                  </p>
                </div>
              </div>
              <p className="commerce-spotlight__overview-description">
                Real Testing, Real Life, Real Results
              </p>
            </div>
            <div className="commerce-spotlight__shop">
              <h4 className="commerce-spotlight__shop-heading">
                Shop Our Top Picks
              </h4>
              <div
                id="commerce-spotlight__shop-cards_1-0"
                className="comp commerce-spotlight__shop-cards universal-card-list mntl-universal-card-list mntl-document-card-list mntl-card-list mntl-block"
                data-chunk={36}
              >
                <a
                  id="mntl-card-list-items_8-0"
                  className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card card--no-image"
                  data-doc-id={4158720}
                  data-tax-levels=""
                  href="https://www.thespruce.com/best-grow-lights-4158720"
                  data-ordinal={1}
                >
                  <div className="loc card__top">
                    <div className="card__media mntl-universal-image card__media universal-image__container">
                      <div
                        className="img-placeholder"
                        style={{ paddingBottom: "66.0%" }}
                      >
                        <img
                          data-src="https://www.thespruce.com/thmb/RDpD9-eUQGPXfwEiCP4s8MhW4WI=/315x208/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-grow-lights-test-aero-garden-trio-rachel-marek-00039_crop-1-b9a4dcde6b5a4d65835a01dc1b2dc009.jpeg"
                          width={315}
                          height={208}
                          alt="Aero Garden Trio set up above plants"
                          className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                          data-expand={300}
                          src="https://www.thespruce.com/thmb/RDpD9-eUQGPXfwEiCP4s8MhW4WI=/315x208/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-grow-lights-test-aero-garden-trio-rachel-marek-00039_crop-1-b9a4dcde6b5a4d65835a01dc1b2dc009.jpeg"
                        />
                        <noscript>
                          &lt;img
                          src="https://www.thespruce.com/thmb/RDpD9-eUQGPXfwEiCP4s8MhW4WI=/315x208/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-grow-lights-test-aero-garden-trio-rachel-marek-00039_crop-1-b9a4dcde6b5a4d65835a01dc1b2dc009.jpeg"
                          width="315" height="208" class="img--noscript
                          card__img universal-image__image" alt="Aero Garden
                          Trio set up above plants" /&gt;
                        </noscript>
                      </div>
                    </div>
                  </div>
                  <div className="card__content ">
                    <span className="card__title">
                      <span className="card__title-text ">
                        10 Grow Lights We Tested For Keeping Plants Happy All
                        Winter
                      </span>
                    </span>
                  </div>
                </a>
                <a
                  id="mntl-card-list-items_9-0"
                  className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card card--no-image"
                  data-doc-id={5524921}
                  data-tax-levels=""
                  href="https://www.thespruce.com/best-smokeless-fire-pits-5524921"
                  data-ordinal={2}
                >
                  <div className="loc card__top">
                    <div className="card__media mntl-universal-image card__media universal-image__container">
                      <div
                        className="img-placeholder"
                        style={{ paddingBottom: "66.0%" }}
                      >
                        <img
                          data-src="https://www.thespruce.com/thmb/wnhKXavAhWTI--HsP4D0-8E4AH0=/315x208/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-smokeless-fire-pits-test-solo-stove-2-0-carolyn-malcoun-01-2-0c62ae4e0d904d1a8f6cd83ea09b54a4.jpeg"
                          width={315}
                          height={208}
                          alt="Fire burning in a Solo Stove Bonfire 2.0 in a backyard"
                          className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                          data-expand={300}
                          src="https://www.thespruce.com/thmb/wnhKXavAhWTI--HsP4D0-8E4AH0=/315x208/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-smokeless-fire-pits-test-solo-stove-2-0-carolyn-malcoun-01-2-0c62ae4e0d904d1a8f6cd83ea09b54a4.jpeg"
                        />
                        <noscript>
                          &lt;img
                          src="https://www.thespruce.com/thmb/wnhKXavAhWTI--HsP4D0-8E4AH0=/315x208/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spr-smokeless-fire-pits-test-solo-stove-2-0-carolyn-malcoun-01-2-0c62ae4e0d904d1a8f6cd83ea09b54a4.jpeg"
                          width="315" height="208" class="img--noscript
                          card__img universal-image__image" alt="Fire burning in
                          a Solo Stove Bonfire 2.0 in a backyard" /&gt;
                        </noscript>
                      </div>
                    </div>
                  </div>
                  <div className="card__content ">
                    <span className="card__title">
                      <span className="card__title-text ">
                        We Tried These Cozy Smokeless Fire Pits and They Really
                        Work
                      </span>
                    </span>
                  </div>
                </a>
              </div>{" "}
            </div>
          </div>
        </div>

        <div
          id="review-board_1-0"
          className="comp review-board review-board--home"
        >
          {" "}
          <div className="review-board__wrapper">
            <div className="review-board-info__title-block">
              <div className="review-board-info__title-block-media">
                <div className="review-board-info__title-block-img mntl-universal-image universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "40.0%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/CMVvpDp4x8wPSLYrRcoNxlgJCKc=/908x364/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/homereviewboardillo-c91f2ce96e75497f81f98aa237ecf944.png"
                      width={908}
                      height={364}
                      alt="illustration of a man potting a plant, a woman doing research on a computer, and another woman vacuuming"
                      className="universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.thespruce.com/thmb/CMVvpDp4x8wPSLYrRcoNxlgJCKc=/908x364/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/homereviewboardillo-c91f2ce96e75497f81f98aa237ecf944.png"
                    />
                    <noscript>
                      &lt;img
                      src="https://www.thespruce.com/thmb/CMVvpDp4x8wPSLYrRcoNxlgJCKc=/908x364/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/homereviewboardillo-c91f2ce96e75497f81f98aa237ecf944.png"
                      width="908" height="364" class="img--noscript
                      universal-image__image" alt="illustration of a man potting
                      a plant, a woman doing research on a computer, and another
                      woman vacuuming" /&gt;
                    </noscript>
                  </div>
                </div>{" "}
              </div>
              <h3 className="review-board-info__title">
                The Spruce Review Board Process
              </h3>
              <h3 className="review-board-info__subtitle">
                Our Review Boards are made up of certified experts who examine
                our content for accuracy and reliability to ensure that it
                reflects the most up-to-date information. Meet the teams and
                learn more{" "}
                <Link to="/about" onClick={toTop}>
                  about our review process.
                </Link>
              </h3>
            </div>
            <div className="review-board-info__steps">
              <div className="review-board-info__step">
                <span className="review-board-info__step-description">
                  Written
                </span>
              </div>
              <div className="review-board-info__step">
                <span className="review-board-info__step-description">
                  Reviewed
                </span>
              </div>
              <div className="review-board-info__step">
                <span className="review-board-info__step-description">
                  Edited
                </span>
              </div>
              <div className="review-board-info__step">
                <span className="review-board-info__step-description">
                  Approved
                </span>
              </div>
            </div>
            <div className="review-board-team__wrapper">
              <div className="review-board-team__intro-title">
                <h3 className="review-board-team__intro-title-text">
                  Meet Our Review Boards
                </h3>
              </div>
              <div className="review-board-team " style={{}}>
                <div className="review-board-team__title">
                  <h3 className="review-board-team__name">
                    Gardening &amp; Plant Care
                  </h3>
                </div>
                <div className="review-board-team__members-wrapper">
                  <div className="review-board-team__members">
                    <a
                      href="https://www.thespruce.com/julie-thompson-adolf-4777057"
                      rel="nocaes"
                      className="review-board-team__member"
                    >
                      {" "}
                      <div className="review-board-team__member-wrapper">
                        <div className="loc member-img">
                          <div
                            className="img-placeholder"
                            style={{ paddingBottom: "100.0%" }}
                          >
                            <img
                              data-src="https://www.thespruce.com/thmb/BadAZnIx66i2nmW6FZQckt-VODY=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/headshots_FINAL_julie-thompson-adolf-4060bdd452de446fbd5d1bf9e1d737a1.png"
                              width={200}
                              height={200}
                              alt="Julie Thompson-Adolf"
                              className="review-board-team__member-img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                              src="https://www.thespruce.com/thmb/BadAZnIx66i2nmW6FZQckt-VODY=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/headshots_FINAL_julie-thompson-adolf-4060bdd452de446fbd5d1bf9e1d737a1.png"
                            />
                            <noscript>
                              &lt;img
                              src="https://www.thespruce.com/thmb/BadAZnIx66i2nmW6FZQckt-VODY=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/headshots_FINAL_julie-thompson-adolf-4060bdd452de446fbd5d1bf9e1d737a1.png"
                              width="200" height="200" class="img--noscript
                              review-board-team__member-img
                              universal-image__image" alt="Julie Thompson-Adolf"
                              /&gt;
                            </noscript>
                          </div>
                        </div>{" "}
                      </div>
                      <div className="review-board-team__member-info">
                        <span className="review-board-team__member-name">
                          Julie Thompson-Adolf
                        </span>
                        <span className="review-board-team__member-job-title">
                          Master Gardener
                        </span>
                      </div>
                    </a>
                    <a
                      href="https://www.thespruce.com/kathleen-miller-4777056"
                      rel="nocaes"
                      className="review-board-team__member"
                    >
                      {" "}
                      <div className="review-board-team__member-wrapper">
                        <div className="loc member-img">
                          <div
                            className="img-placeholder"
                            style={{ paddingBottom: "100.0%" }}
                          >
                            <img
                              data-src="https://www.thespruce.com/thmb/ybppTdX21yPgRyn-6zBKmgCQnlU=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/headshots_FINAL_kathleen-miller-4357decc3d894b3b81a2551892a297e7.png"
                              width={200}
                              height={200}
                              alt="Kathleen Miller"
                              className="review-board-team__member-img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                              src="https://www.thespruce.com/thmb/ybppTdX21yPgRyn-6zBKmgCQnlU=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/headshots_FINAL_kathleen-miller-4357decc3d894b3b81a2551892a297e7.png"
                            />
                            <noscript>
                              &lt;img
                              src="https://www.thespruce.com/thmb/ybppTdX21yPgRyn-6zBKmgCQnlU=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/headshots_FINAL_kathleen-miller-4357decc3d894b3b81a2551892a297e7.png"
                              width="200" height="200" class="img--noscript
                              review-board-team__member-img
                              universal-image__image" alt="Kathleen Miller"
                              /&gt;
                            </noscript>
                          </div>
                        </div>{" "}
                      </div>
                      <div className="review-board-team__member-info">
                        <span className="review-board-team__member-name">
                          Kathleen Miller
                        </span>
                        <span className="review-board-team__member-job-title">
                          Master Gardener and Horticulturist
                        </span>
                      </div>
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="review-board-team " style={{}}>
                <div className="review-board-team__title">
                  <h3 className="review-board-team__name">
                    Renovation &amp; Repair
                  </h3>
                </div>
                <div className="review-board-team__members-wrapper">
                  <div className="review-board-team__members">
                    <a
                      href="https://www.thespruce.com/deane-biermeier-4777058"
                      rel="nocaes"
                      className="review-board-team__member"
                    >
                      {" "}
                      <div className="review-board-team__member-wrapper">
                        <div className="loc member-img">
                          <div
                            className="img-placeholder"
                            style={{ paddingBottom: "100.0%" }}
                          >
                            <img
                              data-src="https://www.thespruce.com/thmb/1alwOZ4jcilqNBPGK8EaJkwPGlk=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/headshots_FINAL_deane-biermeier-842c201b7d0244dbb856926dd97ed720.png"
                              width={200}
                              height={200}
                              alt="Deane Biermeier"
                              className="review-board-team__member-img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                              src="https://www.thespruce.com/thmb/1alwOZ4jcilqNBPGK8EaJkwPGlk=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/headshots_FINAL_deane-biermeier-842c201b7d0244dbb856926dd97ed720.png"
                            />
                            <noscript>
                              &lt;img
                              src="https://www.thespruce.com/thmb/1alwOZ4jcilqNBPGK8EaJkwPGlk=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/headshots_FINAL_deane-biermeier-842c201b7d0244dbb856926dd97ed720.png"
                              width="200" height="200" class="img--noscript
                              review-board-team__member-img
                              universal-image__image" alt="Deane Biermeier"
                              /&gt;
                            </noscript>
                          </div>
                        </div>{" "}
                      </div>
                      <div className="review-board-team__member-info">
                        <span className="review-board-team__member-name">
                          Deane Biermeirer
                        </span>
                        <span className="review-board-team__member-job-title">
                          Licensed Contractor
                        </span>
                      </div>
                    </a>
                    <a
                      href="https://www.thespruce.com/johnathan-brewer-5071251"
                      rel="nocaes"
                      className="review-board-team__member"
                    >
                      {" "}
                      <div className="review-board-team__member-wrapper">
                        <div className="loc member-img">
                          <div
                            className="img-placeholder"
                            style={{ paddingBottom: "100.0%" }}
                          >
                            <img
                              data-src="https://www.thespruce.com/thmb/wnevBb66Jdq8bgnVQh3yusTAHkg=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Johnathan_Brewer_square2-86ce9c25c6a2458682379e8e6ef3fc35.png"
                              width={200}
                              height={200}
                              alt="Johnathan Brewer"
                              className="review-board-team__member-img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                              src="https://www.thespruce.com/thmb/wnevBb66Jdq8bgnVQh3yusTAHkg=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Johnathan_Brewer_square2-86ce9c25c6a2458682379e8e6ef3fc35.png"
                            />
                            <noscript>
                              &lt;img
                              src="https://www.thespruce.com/thmb/wnevBb66Jdq8bgnVQh3yusTAHkg=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Johnathan_Brewer_square2-86ce9c25c6a2458682379e8e6ef3fc35.png"
                              width="200" height="200" class="img--noscript
                              review-board-team__member-img
                              universal-image__image" alt="Johnathan Brewer"
                              /&gt;
                            </noscript>
                          </div>
                        </div>{" "}
                      </div>
                      <div className="review-board-team__member-info">
                        <span className="review-board-team__member-name">
                          Johnathan Brewer
                        </span>
                        <span className="review-board-team__member-job-title">
                          Licensed General Contractor
                        </span>
                      </div>
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="review-board-team " style={{}}>
                <div className="review-board-team__title">
                  <h3 className="review-board-team__name">
                    Cleaning &amp; Organization
                  </h3>
                </div>
                <div className="review-board-team__members-wrapper">
                  <div className="review-board-team__members">
                    <a
                      href="https://www.thespruce.com/rhea-mehta-5202259"
                      rel="nocaes"
                      className="review-board-team__member"
                    >
                      {" "}
                      <div className="review-board-team__member-wrapper">
                        <div className="loc member-img">
                          <div
                            className="img-placeholder"
                            style={{ paddingBottom: "100.0%" }}
                          >
                            <img
                              data-src="https://www.thespruce.com/thmb/1QzPfqQwfkLFNNDl8qm36WO8fU8=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/RheaMehta-5ec1f7a6a5504a6d9c291175b5631903.png"
                              width={200}
                              height={200}
                              alt="Dr. Rhea Mehta, PhD"
                              className="review-board-team__member-img universal-image__image lazyloadwait lazyloaded"
                              src="https://www.thespruce.com/thmb/1QzPfqQwfkLFNNDl8qm36WO8fU8=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/RheaMehta-5ec1f7a6a5504a6d9c291175b5631903.png"
                            />
                            <noscript>
                              &lt;img
                              src="https://www.thespruce.com/thmb/1QzPfqQwfkLFNNDl8qm36WO8fU8=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/RheaMehta-5ec1f7a6a5504a6d9c291175b5631903.png"
                              width="200" height="200" class="img--noscript
                              review-board-team__member-img
                              universal-image__image" alt="Dr. Rhea Mehta, PhD"
                              /&gt;
                            </noscript>
                          </div>
                        </div>{" "}
                      </div>
                      <div className="review-board-team__member-info">
                        <span className="review-board-team__member-name">
                          Dr. Rhea Mehta, HBSc, PhD
                        </span>
                        <span className="review-board-team__member-job-title">
                          Environmental Toxicologist
                        </span>
                      </div>
                    </a>
                    <a
                      href="https://www.thespruce.com/amanda-rose-newton-5203436"
                      rel="nocaes"
                      className="review-board-team__member"
                    >
                      {" "}
                      <div className="review-board-team__member-wrapper">
                        <div className="loc member-img">
                          <div
                            className="img-placeholder"
                            style={{ paddingBottom: "100.0%" }}
                          >
                            <img
                              data-src="https://www.thespruce.com/thmb/xc9f-nsdhoHhsKiSYYEeEg3H5S8=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/AmandaRose-827ab33c1aa94ede8786ab914e443824.png"
                              width={200}
                              height={200}
                              alt="Amanda Rose Newton"
                              className="review-board-team__member-img universal-image__image lazyloadwait lazyloaded"
                              src="https://www.thespruce.com/thmb/xc9f-nsdhoHhsKiSYYEeEg3H5S8=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/AmandaRose-827ab33c1aa94ede8786ab914e443824.png"
                            />
                            <noscript>
                              &lt;img
                              src="https://www.thespruce.com/thmb/xc9f-nsdhoHhsKiSYYEeEg3H5S8=/200x200/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/AmandaRose-827ab33c1aa94ede8786ab914e443824.png"
                              width="200" height="200" class="img--noscript
                              review-board-team__member-img
                              universal-image__image" alt="Amanda Rose Newton"
                              /&gt;
                            </noscript>
                          </div>
                        </div>{" "}
                      </div>
                      <div className="review-board-team__member-info">
                        <span className="review-board-team__member-name">
                          Amanda Rose Newton, MS
                        </span>
                        <span className="review-board-team__member-job-title">
                          Board Certified Entomologist
                        </span>
                      </div>
                    </a>{" "}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>

        <section
          id="three-post_1-0"
          className="comp three-post mntl-three-post mntl-block"
          data-tracking-container="true"
        >
          <a
            href="https://www.thespruce.com/sales-and-deals-5070016"
            rel="nocaes"
            id="mntl-three-post__title_2-0"
            className="mntl-three-post__title mntl-section-title type--giraffe-link is-link"
            data-tracking-container="true"
            style={{ textDecoration: "none" }}
          >
            <h2
              id="mntl-section-title__heading_2-0"
              className="comp mntl-section-title__heading mntl-text-block type--giraffe-link"
            >
              Just In
            </h2>
          </a>
          <div
            id="mntl-three-post__inner_2-0"
            className="comp mntl-three-post__inner mntl-universal-card-list mntl-document-card-list mntl-card-list mntl-block"
            data-chunk={36}
          >
            <a
              id="mntl-card-list-items_4-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={8733955}
              data-tax-levels=""
              href="https://www.thespruce.com/holiday-gift-ideas-2024-8733955"
              data-ordinal={1}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/7i6EVJhCJ5c-yTQK7qK93CemRyg=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/462_websize-4e9fd923510f4c3cbb7d4ec8fc513f60.jpg"
                      width={728}
                      height={484}
                      alt="Person add a pink ornament on an artificial Christmas tree"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/7i6EVJhCJ5c-yTQK7qK93CemRyg=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/462_websize-4e9fd923510f4c3cbb7d4ec8fc513f60.jpg"
                    />
                  </div>
                </div>
              </div>
              <div
                className="card__content "
                data-tag="Gifts for Family & Friends"
              >
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    How to Decorate Your Christmas Tree Like a Designer (Without
                    Splurging on One)
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Staff Author"
                  data-author="Staff Author"
                />
              </div>
            </a>
            <a
              id="mntl-card-list-items_5-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={4157464}
              data-tax-levels=""
              href="https://www.thespruce.com/best-chocolate-gifts-4157464"
              data-ordinal={2}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/g_kgticUgjNbxOb6151cwD0F_a0=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spruce-christmascactus-Olga_Anourina-9f11f239a2324e55b95470f9f0487077.jpg"
                      width={728}
                      height={484}
                      alt="Best Chocolate Gifts"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/g_kgticUgjNbxOb6151cwD0F_a0=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/spruce-christmascactus-Olga_Anourina-9f11f239a2324e55b95470f9f0487077.jpg"
                    />
                  </div>
                </div>
              </div>
              <div
                className="card__content "
                data-tag="Gifts for Family & Friends"
              >
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    Are You Watering Your Christmas Cactus Enough or Way Too
                    Much? What to Know
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Julia Fields"
                  data-author="Patrice Williams"
                />
              </div>
            </a>
            <a
              id="mntl-card-list-items_6-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={1666064}
              data-tax-levels=""
              href="https://www.thespruce.com/buy-gift-baskets-online-1666064"
              data-ordinal={3}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/Xgstze92SgAUoglQuaECu7N8bMI=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/openspacesdesk-2c650bf4b1624cf090eda474811df9b4.jpeg"
                      width={728}
                      height={484}
                      alt="The Gourmet Gift Baskets White Wine Gift Basket is partially opened with the box it arrives in behind it."
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/Xgstze92SgAUoglQuaECu7N8bMI=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/openspacesdesk-2c650bf4b1624cf090eda474811df9b4.jpeg"
                    />
                  </div>
                </div>
              </div>
              <div
                className="card__content "
                data-tag="Gifts for Family & Friends"
              >
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    I Buy Presents for My Coworkers Every Year—Here Are My
                    Failproof Gifts Under $40
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Julia Fields"
                  data-author="Camryn Rabideau"
                />
              </div>
            </a>
          </div>
        </section>

        <section
          id="three-post_1-0"
          className="comp three-post mntl-three-post mntl-block"
          data-tracking-container="true"
        >
          <a
            href="https://www.thespruce.com/sales-and-deals-5070016"
            rel="nocaes"
            id="mntl-three-post__title_2-0"
            className="mntl-three-post__title mntl-section-title type--giraffe-link is-link"
            data-tracking-container="true"
            style={{ textDecoration: "none" }}
          >
            <h2
              id="mntl-section-title__heading_2-0"
              className="comp mntl-section-title__heading mntl-text-block type--giraffe-link"
            >
              Holiday Ready
            </h2>
          </a>
          <div
            id="mntl-three-post__inner_2-0"
            className="comp mntl-three-post__inner mntl-universal-card-list mntl-document-card-list mntl-card-list mntl-block"
            data-chunk={36}
          >
            <a
              id="mntl-card-list-items_4-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={8733955}
              data-tax-levels=""
              href="https://www.thespruce.com/holiday-gift-ideas-2024-8733955"
              data-ordinal={1}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/nW4eY_c363WL_1eyUnSzsVPL5og=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/DesignbyEmilyHendersonDesign_WinterMountainHouse_PhotobySaraLiggoria-Tramp_9-3110ceeb4e2a48b98fff2f342d9e49a6-ca8121170a404c219310092f86109e58.jpeg"
                      width={728}
                      height={484}
                      alt="Person add a pink ornament on an artificial Christmas tree"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/nW4eY_c363WL_1eyUnSzsVPL5og=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/DesignbyEmilyHendersonDesign_WinterMountainHouse_PhotobySaraLiggoria-Tramp_9-3110ceeb4e2a48b98fff2f342d9e49a6-ca8121170a404c219310092f86109e58.jpeg"
                    />
                  </div>
                </div>
              </div>
              <div
                className="card__content "
                data-tag="Gifts for Family & Friends"
              >
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    Decorate Your Bathroom for Christmas: 32 Stylish and Festive
                    Ideas
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Staff Author"
                  data-author="Staff Author"
                />
              </div>
            </a>
            <a
              id="mntl-card-list-items_5-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={4157464}
              data-tax-levels=""
              href="https://www.thespruce.com/best-chocolate-gifts-4157464"
              data-ordinal={2}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/rV_Cy_vJcSKy-IV7cpuItFljMTk=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Emily-Henderson-Design-Photo-by-Kaitlin-Green-0fe2fcccf6c946b8a1e82fff1f0cc632.jpg"
                      width={728}
                      height={484}
                      alt="Best Chocolate Gifts"
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/rV_Cy_vJcSKy-IV7cpuItFljMTk=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Emily-Henderson-Design-Photo-by-Kaitlin-Green-0fe2fcccf6c946b8a1e82fff1f0cc632.jpg"
                    />
                  </div>
                </div>
              </div>
              <div
                className="card__content "
                data-tag="Gifts for Family & Friends"
              >
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    6 Tips for Decorating Your Holiday Mantel to Make It Stand
                    Out
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Julia Fields"
                  data-author="Patrice Williams"
                />
              </div>
            </a>
            <a
              id="mntl-card-list-items_6-0"
              className="comp mntl-card-list-items mntl-universal-card mntl-document-card mntl-card card--image-top card card--no-image"
              data-doc-id={1666064}
              data-tax-levels=""
              href="https://www.thespruce.com/buy-gift-baskets-online-1666064"
              data-ordinal={3}
            >
              <div className="loc card__top">
                <div className="card__media mntl-universal-image card__media universal-image__container">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.4%" }}
                  >
                    <img
                      data-src="https://www.thespruce.com/thmb/qnZG5OwmA89xG08k7UnA5wfdj_Q=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/SPR-christmas-kitchen-decor-ideas-8733092-867fc47a863841c38232cd1357749c6c.jpg"
                      width={728}
                      height={484}
                      alt="The Gourmet Gift Baskets White Wine Gift Basket is partially opened with the box it arrives in behind it."
                      className="card__img universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      data-expand={300}
                      src="https://www.thespruce.com/thmb/qnZG5OwmA89xG08k7UnA5wfdj_Q=/728x484/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/SPR-christmas-kitchen-decor-ideas-8733092-867fc47a863841c38232cd1357749c6c.jpg"
                    />
                  </div>
                </div>
              </div>
              <div
                className="card__content "
                data-tag="Gifts for Family & Friends"
              >
                <div className="card__header" />
                <span className="card__title">
                  <span className="card__title-text ">
                    38 Christmas Kitchen Decor Ideas for Extra Holiday Cheer
                    While Cooking
                  </span>
                </span>
                <div
                  className="card__byline mntl-card__byline "
                  data-byline="By Julia Fields"
                  data-author="Camryn Rabideau"
                />
              </div>
            </a>
          </div>
        </section>

        <section
          id="video-playlist_1-0"
          className="comp video-playlist l-container-med"
        >
          {" "}
          <header className="video-playlist__header">What to Watch Now</header>
          <div className="video-playlist__current-video">
            <div className="video-playlist__video-wrapper">
              <div
                id="video-playlist__video_1-0"
                className="jwplayer jw-reset jw-state-idle jw-stretch-uniform jw-flag-aspect-mode jw-skin-mantle jw-floating-dismissible jw-keep-thumbnail jw-flag-user-inactive jw-breakpoint-5"
                tabIndex={0}
                aria-label="Video Player"
                aria-describedby="jw-video-playlist__video_1-0-shortcuts-tooltip-explanation"
                style={{ width: "100%" }}
              >
                <div
                  className="jw-aspect jw-reset"
                  style={{ paddingTop: "56.25%" }}
                />
                <div className="jw-wrapper jw-reset">
                  <div
                    style={{
                      opacity: 0,
                      visibility: "hidden",
                      overflow: "hidden",
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        overflow: "auto",
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div style={{ height: 1, width: 806 }} />
                    </div>
                    <div
                      className="jw-contract-trigger"
                      style={{
                        overflow: "auto",
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <div className="jw-top jw-reset" />
                  <div
                    className="jw-aspect jw-reset"
                    style={{ paddingTop: "56.25%" }}
                  />
                  <div className="jw-media jw-reset">
                    <video
                      className="jw-video jw-reset"
                      tabIndex={-1}
                      disableremoteplayback=""
                      webkit-playsinline=""
                      playsInline=""
                      src="blob:https://www.thespruce.com/4e9c1969-29f2-466c-a551-969bc80f6ce5"
                    />
                  </div>
                  <div
                    className="jw-preview jw-reset"
                    style={{
                      backgroundImage:
                        'url("https://cdn.jwplayer.com/v2/media/Jn8mpBFh/poster.jpg?width=720")',
                      backgroundSize: "cover",
                    }}
                  />
                  <div className="jw-controls-backdrop jw-reset" />
                  <div
                    className="jw-captions jw-reset"
                    style={{ fontSize: 25 }}
                  />
                  <div
                    className="jw-title jw-reset-text"
                    dir="auto"
                    style={{ display: "none" }}
                  >
                    <div className="jw-title-primary jw-reset-text" />
                    <div className="jw-title-secondary jw-reset-text" />
                  </div>
                  <div className="jw-overlays jw-reset">
                    <div
                      id="video-playlist__video_1-0_jwpsrv"
                      className="jw-plugin jw-reset"
                    />
                    <div
                      id="video-playlist__video_1-0_bidding"
                      className="jw-plugin jw-reset"
                    />
                    <div
                      id="video-playlist__video_1-0_related"
                      className="jw-plugin jw-reset"
                    />
                  </div>
                  <div className="jw-hidden-accessibility">
                    <span className="jw-time-update" aria-live="assertive">
                      0 seconds of 2 minutes, 58 seconds
                    </span>
                    <span className="jw-volume-update" aria-live="assertive">
                      Volume 90%
                    </span>
                  </div>
                  <div
                    className="jw-shortcuts-tooltip jw-modal jw-reset"
                    title="Keyboard Shortcuts"
                  >
                    <div
                      className="jw-icon jw-icon-inline jw-button-color jw-reset jw-shortcuts-close"
                      tabIndex={0}
                      aria-label="Close"
                      style={{}}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="jw-svg-icon jw-svg-icon-close"
                        viewBox="0 0 240 240"
                        focusable="false"
                      >
                        <path d="M134.8,120l48.6-48.6c2-1.9,2.1-5.2,0.2-7.2c0,0-0.1-0.1-0.2-0.2l-7.4-7.4c-1.9-2-5.2-2.1-7.2-0.2c0,0-0.1,0.1-0.2,0.2L120,105.2L71.4,56.6c-1.9-2-5.2-2.1-7.2-0.2c0,0-0.1,0.1-0.2,0.2L56.6,64c-2,1.9-2.1,5.2-0.2,7.2c0,0,0.1,0.1,0.2,0.2l48.6,48.7l-48.6,48.6c-2,1.9-2.1,5.2-0.2,7.2c0,0,0.1,0.1,0.2,0.2l7.4,7.4c1.9,2,5.2,2.1,7.2,0.2c0,0,0.1-0.1,0.2-0.2l48.7-48.6l48.6,48.6c1.9,2,5.2,2.1,7.2,0.2c0,0,0.1-0.1,0.2-0.2l7.4-7.4c2-1.9,2.1-5.2,0.2-7.2c0,0-0.1-0.1-0.2-0.2L134.8,120z" />
                      </svg>
                    </div>
                    <span
                      className="jw-hidden"
                      id="jw-video-playlist__video_1-0-shortcuts-tooltip-explanation"
                    >
                      Press shift question mark to access a list of keyboard
                      shortcuts
                    </span>
                    <div className="jw-reset jw-shortcuts-container">
                      <div className="jw-reset jw-shortcuts-header">
                        <span className="jw-reset jw-shortcuts-title">
                          Keyboard Shortcuts
                        </span>
                        <button
                          aria-label="Keyboard Shortcuts"
                          className="jw-reset jw-switch"
                        >
                          <span className="jw-reset jw-switch-knob" />
                          <span className="jw-reset-text jw-switch-enabled">
                            Enabled
                          </span>
                          <span className="jw-reset-text jw-switch-disabled">
                            Disabled
                          </span>
                        </button>
                      </div>
                      <div className="jw-reset jw-shortcuts-tooltip-list">
                        <div className="jw-shortcuts-tooltip-descriptions jw-reset">
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Shortcuts Open/Close
                            </span>
                            <span className="jw-shortcuts-key jw-reset">
                              / or ?
                            </span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Play/Pause
                            </span>
                            <span className="jw-shortcuts-key jw-reset">
                              SPACE
                            </span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Increase Volume
                            </span>
                            <span className="jw-shortcuts-key jw-reset">↑</span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Decrease Volume
                            </span>
                            <span className="jw-shortcuts-key jw-reset">↓</span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Seek Forward
                            </span>
                            <span className="jw-shortcuts-key jw-reset">→</span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Seek Backward
                            </span>
                            <span className="jw-shortcuts-key jw-reset">←</span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Captions On/Off
                            </span>
                            <span className="jw-shortcuts-key jw-reset">c</span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Fullscreen/Exit Fullscreen
                            </span>
                            <span className="jw-shortcuts-key jw-reset">f</span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Mute/Unmute
                            </span>
                            <span className="jw-shortcuts-key jw-reset">m</span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Decrease Caption Size
                            </span>
                            <span className="jw-shortcuts-key jw-reset">-</span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Increase Caption Size
                            </span>
                            <span className="jw-shortcuts-key jw-reset">
                              + or =
                            </span>
                          </div>
                          <div
                            className="jw-shortcuts-row jw-reset"
                            tabIndex={0}
                          >
                            <span className="jw-shortcuts-description jw-reset">
                              Seek %
                            </span>
                            <span className="jw-shortcuts-key jw-reset">
                              0-9
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="jw-float-bar jw-reset">
                    <div className="jw-float-bar-title" aria-label=" ">
                      {" "}
                    </div>
                    <div
                      className="jw-float-bar-icon jw-float-icon jw-icon jw-button-color jw-reset"
                      aria-label="Close"
                      tabIndex={0}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 11 11"
                        fill="none"
                        className="jw-svg-icon jw-svg-icon-floating-close"
                        focusable="false"
                      >
                        <path
                          d="M7.41499 5.00001L10.705 1.70501C10.8688 1.51371 10.9544 1.26763 10.9447 1.01595C10.935 0.764278 10.8307 0.525539 10.6526 0.347444C10.4745 0.16935 10.2357 0.0650171 9.98405 0.055296C9.73237 0.0455748 9.48629 0.131181 9.29499 0.295007L5.99999 3.58501L2.70499 0.295007C2.51369 0.131181 2.26762 0.0455748 2.01594 0.055296C1.76426 0.0650171 1.52552 0.16935 1.34743 0.347444C1.16934 0.525539 1.065 0.764278 1.05528 1.01595C1.04556 1.26763 1.13117 1.51371 1.29499 1.70501L4.58499 5.00001L1.29499 8.29501C1.19031 8.38466 1.10529 8.49497 1.04527 8.61904C0.985244 8.7431 0.951515 8.87824 0.946195 9.01596C0.940876 9.15367 0.964081 9.29101 1.01436 9.41933C1.06463 9.54766 1.14089 9.6642 1.23834 9.76166C1.3358 9.85911 1.45235 9.93537 1.58067 9.98565C1.709 10.0359 1.84633 10.0591 1.98405 10.0538C2.12177 10.0485 2.2569 10.0148 2.38096 9.95473C2.50503 9.89471 2.61535 9.80969 2.70499 9.70501L5.99999 6.41501L9.29499 9.70501C9.48629 9.86884 9.73237 9.95444 9.98405 9.94472C10.2357 9.935 10.4745 9.83067 10.6526 9.65257C10.8307 9.47448 10.935 9.23574 10.9447 8.98406C10.9544 8.73239 10.8688 8.48631 10.705 8.29501L7.41499 5.00001Z"
                          fill="white"
                          fillOpacity="0.8"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="jw-controls jw-reset">
                    <div className="jw-display jw-reset">
                      <div className="jw-display-container jw-reset">
                        <div className="jw-display-controls jw-reset">
                          <div className="jw-display-icon-container jw-display-icon-rewind jw-reset">
                            <div
                              className="jw-icon jw-icon-rewind jw-button-color jw-reset"
                              tabIndex={0}
                              aria-label="Rewind 10 Seconds"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="jw-svg-icon jw-svg-icon-rewind"
                                viewBox="0 0 240 240"
                                focusable="false"
                              >
                                <path d="M113.2,131.078a21.589,21.589,0,0,0-17.7-10.6,21.589,21.589,0,0,0-17.7,10.6,44.769,44.769,0,0,0,0,46.3,21.589,21.589,0,0,0,17.7,10.6,21.589,21.589,0,0,0,17.7-10.6,44.769,44.769,0,0,0,0-46.3Zm-17.7,47.2c-7.8,0-14.4-11-14.4-24.1s6.6-24.1,14.4-24.1,14.4,11,14.4,24.1S103.4,178.278,95.5,178.278Zm-43.4,9.7v-51l-4.8,4.8-6.8-6.8,13-13a4.8,4.8,0,0,1,8.2,3.4v62.7l-9.6-.1Zm162-130.2v125.3a4.867,4.867,0,0,1-4.8,4.8H146.6v-19.3h48.2v-96.4H79.1v19.3c0,5.3-3.6,7.2-8,4.3l-41.8-27.9a6.013,6.013,0,0,1-2.7-8,5.887,5.887,0,0,1,2.7-2.7l41.8-27.9c4.4-2.9,8-1,8,4.3v19.3H209.2A4.974,4.974,0,0,1,214.1,57.778Z" />
                              </svg>
                            </div>
                          </div>
                          <div className="jw-display-icon-container jw-display-icon-display jw-reset"></div>
                          <div
                            className="jw-display-icon-container jw-display-icon-next jw-reset"
                            style={{}}
                          >
                            <div
                              className="jw-icon jw-icon-next jw-button-color jw-reset"
                              tabIndex={0}
                              aria-label="Next"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="jw-svg-icon jw-svg-icon-next"
                                viewBox="0 0 240 240"
                                focusable="false"
                              >
                                <path d="M165,60v53.3L59.2,42.8C56.9,41.3,55,42.3,55,45v150c0,2.7,1.9,3.8,4.2,2.2L165,126.6v53.3h20v-120L165,60L165,60z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="jw-nextup-container jw-reset">
                      <div className="jw-nextup jw-background-color jw-reset jw-nextup-thumbnail-visible">
                        <div className="jw-nextup-tooltip jw-reset">
                          <div
                            className="jw-nextup-thumbnail jw-reset"
                            style={{
                              backgroundImage:
                                'url("https://cdn.jwplayer.com/v2/media/t6NqzHY8/poster.jpg?width=120")',
                            }}
                          />
                          <div className="jw-nextup-body jw-reset">
                            <div className="jw-nextup-header jw-reset">
                              Next Up
                            </div>
                            <div
                              className="jw-nextup-title jw-reset-text"
                              dir="auto"
                            >
                              5 Great Home Bar Ideas
                            </div>
                            <div className="jw-nextup-duration jw-reset">
                              01:06
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="jw-icon jw-nextup-close jw-reset"
                          aria-label="Close"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-close"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M134.8,120l48.6-48.6c2-1.9,2.1-5.2,0.2-7.2c0,0-0.1-0.1-0.2-0.2l-7.4-7.4c-1.9-2-5.2-2.1-7.2-0.2c0,0-0.1,0.1-0.2,0.2L120,105.2L71.4,56.6c-1.9-2-5.2-2.1-7.2-0.2c0,0-0.1,0.1-0.2,0.2L56.6,64c-2,1.9-2.1,5.2-0.2,7.2c0,0,0.1,0.1,0.2,0.2l48.6,48.7l-48.6,48.6c-2,1.9-2.1,5.2-0.2,7.2c0,0,0.1,0.1,0.2,0.2l7.4,7.4c1.9,2,5.2,2.1,7.2,0.2c0,0,0.1-0.1,0.2-0.2l48.7-48.6l48.6,48.6c1.9,2,5.2,2.1,7.2,0.2c0,0,0.1-0.1,0.2-0.2l7.4-7.4c2-1.9,2.1-5.2,0.2-7.2c0,0-0.1-0.1-0.2-0.2L134.8,120z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div
                      id="jw-video-playlist__video_1-0-settings-menu"
                      className="jw-reset jw-settings-menu"
                      aria-expanded="false"
                      aria-label="Settings"
                    >
                      <div className="jw-reset jw-settings-topbar">
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset jw-settings-back"
                          tabIndex={0}
                          aria-label="Previous"
                          style={{ display: "none" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-arrow-left"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M55.4,104.4c-1.1,1.1-2.2,2.3-3.1,3.6c-6.9,9.9-4.4,23.5,5.5,30.4L159.7,240l33.9-33.9l-84.9-84.9l84.9-84.9L157.3,0L55.4,104.4L55.4,104.4z" />
                          </svg>
                        </div>
                        <div className="jw-reset jw-settings-topbar-text" />
                        <div className="jw-reset jw-settings-topbar-buttons">
                          <div
                            className="jw-icon jw-icon-inline jw-button-color jw-reset jw-settings-quality jw-submenu-quality"
                            tabIndex={0}
                            aria-label="Quality"
                            name="quality"
                            type="button"
                            aria-expanded="false"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-quality"
                            style={{}}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="jw-svg-icon jw-svg-icon-quality-100"
                              viewBox="0 0 240 240"
                              focusable="false"
                            >
                              <path d="M55,200H35c-3,0-5-2-5-4c0,0,0,0,0-1v-30c0-3,2-5,4-5c0,0,0,0,1,0h20c3,0,5,2,5,4c0,0,0,0,0,1v30C60,198,58,200,55,200L55,200z M110,195v-70c0-3-2-5-4-5c0,0,0,0-1,0H85c-3,0-5,2-5,4c0,0,0,0,0,1v70c0,3,2,5,4,5c0,0,0,0,1,0h20C108,200,110,198,110,195L110,195z M160,195V85c0-3-2-5-4-5c0,0,0,0-1,0h-20c-3,0-5,2-5,4c0,0,0,0,0,1v110c0,3,2,5,4,5c0,0,0,0,1,0h20C158,200,160,198,160,195L160,195z M210,195V45c0-3-2-5-4-5c0,0,0,0-1,0h-20c-3,0-5,2-5,4c0,0,0,0,0,1v150c0,3,2,5,4,5c0,0,0,0,1,0h20C208,200,210,198,210,195L210,195z" />
                            </svg>
                            <div
                              className="jw-reset-text jw-tooltip jw-tooltip-quality"
                              dir="auto"
                            >
                              <div className="jw-text" />
                            </div>
                          </div>
                          <div
                            className="jw-icon jw-icon-inline jw-button-color jw-reset jw-settings-captions jw-submenu-captions"
                            tabIndex={0}
                            aria-label="Closed Captions"
                            name="captions"
                            type="button"
                            aria-expanded="false"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-captions"
                            style={{}}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="jw-svg-icon jw-svg-icon-cc-off"
                              viewBox="0 0 240 240"
                              focusable="false"
                            >
                              <path d="M99.4,97.8c-2.4-0.2-4.8,0.7-6.6,2.3c-1.7,1.7-2.5,4.1-2.4,6.5v25.6c0,9.6,11.6,11.4,17.7,5.5c0.7-0.7,1.5-1.5,2.4-2.3l6.6,7.8c-2.2,2.4-5,4.4-8,5.8c-8,3.5-17.3,2.4-24.3-2.9c-3.9-3.6-5.9-8.7-5.5-14v-25.6c0-2.7,0.5-5.3,1.5-7.8c0.9-2.2,2.4-4.3,4.2-5.9c5.7-4.5,13.2-6.2,20.3-4.6c3.3,0.5,6.3,2,8.7,4.3c1.3,1.3,2.5,2.6,3.5,4.2l-7.1,6.9C107.9,100,103.8,97.8,99.4,97.8z M144.1,97.8c-2.4-0.2-4.8,0.7-6.6,2.3c-1.7,1.7-2.5,4.1-2.4,6.5v25.6c0,9.6,11.6,11.4,17.7,5.5c0.7-0.7,1.5-1.5,2.4-2.3l6.6,7.8c-2.2,2.4-5,4.4-8,5.8c-8,3.5-17.3,2.4-24.3-2.9c-3.9-3.6-5.9-8.7-5.5-14v-25.6c0-2.7,0.5-5.3,1.5-7.8c0.9-2.2,2.4-4.3,4.2-5.9c5.7-4.5,13.2-6.2,20.3-4.6c3.3,0.5,6.3,2,8.7,4.3c1.3,1.3,2.5,2.6,3.5,4.2l-7.1,6.9C152.6,100,148.5,97.8,144.1,97.8L144.1,97.8z M200,60v120H40V60H200 M215,40H25c-2.7,0-5,2.2-5,5v150c0,2.7,2.2,5,5,5h190c2.7,0,5-2.2,5-5V45C220,42.2,217.8,40,215,40z" />
                            </svg>
                            <div
                              className="jw-reset-text jw-tooltip jw-tooltip-captions"
                              dir="auto"
                            >
                              <div className="jw-text" />
                            </div>
                          </div>
                          <div
                            className="jw-icon jw-icon-inline jw-button-color jw-reset jw-settings-close"
                            tabIndex={0}
                            aria-label="Close"
                            style={{}}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="jw-svg-icon jw-svg-icon-close"
                              viewBox="0 0 240 240"
                              focusable="false"
                            >
                              <path d="M134.8,120l48.6-48.6c2-1.9,2.1-5.2,0.2-7.2c0,0-0.1-0.1-0.2-0.2l-7.4-7.4c-1.9-2-5.2-2.1-7.2-0.2c0,0-0.1,0.1-0.2,0.2L120,105.2L71.4,56.6c-1.9-2-5.2-2.1-7.2-0.2c0,0-0.1,0.1-0.2,0.2L56.6,64c-2,1.9-2.1,5.2-0.2,7.2c0,0,0.1,0.1,0.2,0.2l48.6,48.7l-48.6,48.6c-2,1.9-2.1,5.2-0.2,7.2c0,0,0.1,0.1,0.2,0.2l7.4,7.4c1.9,2,5.2,2.1,7.2,0.2c0,0,0.1-0.1,0.2-0.2l48.7-48.6l48.6,48.6c1.9,2,5.2,2.1,7.2,0.2c0,0,0.1-0.1,0.2-0.2l7.4-7.4c2-1.9,2.1-5.2,0.2-7.2c0,0-0.1-0.1-0.2-0.2L134.8,120z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-captions"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-captions"
                        aria-expanded="false"
                        aria-label="Closed Captions"
                      >
                        <div className="jw-reset jw-submenu-topbar">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Subtitle Settings"
                            dir="auto"
                          >
                            Subtitle Settings
                          </button>
                        </div>
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Off"
                            dir="auto"
                          >
                            Off
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="English"
                            dir="auto"
                          >
                            English
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-captionsSettings"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-captionsSettings"
                        aria-expanded="false"
                        aria-label="Subtitle Settings"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Font Color"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-color"
                          >
                            Font Color
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                White
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Font Opacity"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-fontOpacity"
                          >
                            Font Opacity
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                100%
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Font Size"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-userFontScale"
                          >
                            Font Size
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                100%
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Font Family"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-fontFamily"
                          >
                            Font Family
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                sans-serif
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Character Edge"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-edgeStyle"
                          >
                            Character Edge
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                None
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Edge Color"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-edgeColor"
                          >
                            Edge Color
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                Black
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Background Color"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-backgroundColor"
                          >
                            Background Color
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                Black
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Background Opacity"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-backgroundOpacity"
                          >
                            Background Opacity
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                75%
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Window Color"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-windowColor"
                          >
                            Window Color
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                Black
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Window Opacity"
                            aria-expanded="false"
                            dir="auto"
                            aria-controls="jw-video-playlist__video_1-0-settings-submenu-windowOpacity"
                          >
                            Window Opacity
                            <div className="jw-reset jw-settings-value-wrapper">
                              <div className="jw-reset-text jw-settings-content-item-value">
                                0%
                              </div>
                              <div className="jw-reset-text jw-settings-content-item-arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="jw-svg-icon jw-svg-icon-arrow-right"
                                  viewBox="0 0 240 240"
                                  focusable="false"
                                >
                                  <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-reset"
                            aria-label="Reset"
                            dir="auto"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-color"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-color"
                        aria-expanded="false"
                        aria-label="Font Color"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="White"
                            dir="auto"
                          >
                            White
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Black"
                            dir="auto"
                          >
                            Black
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Red"
                            dir="auto"
                          >
                            Red
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Green"
                            dir="auto"
                          >
                            Green
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Blue"
                            dir="auto"
                          >
                            Blue
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Yellow"
                            dir="auto"
                          >
                            Yellow
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Magenta"
                            dir="auto"
                          >
                            Magenta
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Cyan"
                            dir="auto"
                          >
                            Cyan
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-fontOpacity"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-fontOpacity"
                        aria-expanded="false"
                        aria-label="Font Opacity"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="100%"
                            dir="auto"
                          >
                            100%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="75%"
                            dir="auto"
                          >
                            75%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="50%"
                            dir="auto"
                          >
                            50%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="25%"
                            dir="auto"
                          >
                            25%
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-userFontScale"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-userFontScale"
                        aria-expanded="false"
                        aria-label="Font Size"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="200%"
                            dir="auto"
                          >
                            200%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="175%"
                            dir="auto"
                          >
                            175%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="150%"
                            dir="auto"
                          >
                            150%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="125%"
                            dir="auto"
                          >
                            125%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="100%"
                            dir="auto"
                          >
                            100%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="75%"
                            dir="auto"
                          >
                            75%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="50%"
                            dir="auto"
                          >
                            50%
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-fontFamily"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-fontFamily"
                        aria-expanded="false"
                        aria-label="Font Family"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Arial"
                            dir="auto"
                          >
                            Arial
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Courier"
                            dir="auto"
                          >
                            Courier
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Georgia"
                            dir="auto"
                          >
                            Georgia
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Impact"
                            dir="auto"
                          >
                            Impact
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Lucida Console"
                            dir="auto"
                          >
                            Lucida Console
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Tahoma"
                            dir="auto"
                          >
                            Tahoma
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Times New Roman"
                            dir="auto"
                          >
                            Times New Roman
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Trebuchet MS"
                            dir="auto"
                          >
                            Trebuchet MS
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Verdana"
                            dir="auto"
                          >
                            Verdana
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-edgeStyle"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-edgeStyle"
                        aria-expanded="false"
                        aria-label="Character Edge"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="None"
                            dir="auto"
                          >
                            None
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Raised"
                            dir="auto"
                          >
                            Raised
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Depressed"
                            dir="auto"
                          >
                            Depressed
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Uniform"
                            dir="auto"
                          >
                            Uniform
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Drop Shadow"
                            dir="auto"
                          >
                            Drop Shadow
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-edgeColor"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-edgeColor"
                        aria-expanded="false"
                        aria-label="Edge Color"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="White"
                            dir="auto"
                          >
                            White
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="Black"
                            dir="auto"
                          >
                            Black
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Red"
                            dir="auto"
                          >
                            Red
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Green"
                            dir="auto"
                          >
                            Green
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Blue"
                            dir="auto"
                          >
                            Blue
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Yellow"
                            dir="auto"
                          >
                            Yellow
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Magenta"
                            dir="auto"
                          >
                            Magenta
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Cyan"
                            dir="auto"
                          >
                            Cyan
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-backgroundColor"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-backgroundColor"
                        aria-expanded="false"
                        aria-label="Background Color"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="White"
                            dir="auto"
                          >
                            White
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="Black"
                            dir="auto"
                          >
                            Black
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Red"
                            dir="auto"
                          >
                            Red
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Green"
                            dir="auto"
                          >
                            Green
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Blue"
                            dir="auto"
                          >
                            Blue
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Yellow"
                            dir="auto"
                          >
                            Yellow
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Magenta"
                            dir="auto"
                          >
                            Magenta
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Cyan"
                            dir="auto"
                          >
                            Cyan
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-backgroundOpacity"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-backgroundOpacity"
                        aria-expanded="false"
                        aria-label="Background Opacity"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="100%"
                            dir="auto"
                          >
                            100%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="75%"
                            dir="auto"
                          >
                            75%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="50%"
                            dir="auto"
                          >
                            50%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="25%"
                            dir="auto"
                          >
                            25%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="0%"
                            dir="auto"
                          >
                            0%
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-windowColor"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-windowColor"
                        aria-expanded="false"
                        aria-label="Window Color"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="White"
                            dir="auto"
                          >
                            White
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="Black"
                            dir="auto"
                          >
                            Black
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Red"
                            dir="auto"
                          >
                            Red
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Green"
                            dir="auto"
                          >
                            Green
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Blue"
                            dir="auto"
                          >
                            Blue
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Yellow"
                            dir="auto"
                          >
                            Yellow
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Magenta"
                            dir="auto"
                          >
                            Magenta
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="Cyan"
                            dir="auto"
                          >
                            Cyan
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-windowOpacity"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-windowOpacity"
                        aria-expanded="false"
                        aria-label="Window Opacity"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="100%"
                            dir="auto"
                          >
                            100%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="75%"
                            dir="auto"
                          >
                            75%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="50%"
                            dir="auto"
                          >
                            50%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="25%"
                            dir="auto"
                          >
                            25%
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="0%"
                            dir="auto"
                          >
                            0%
                          </button>
                        </div>
                      </div>
                      <div
                        id="jw-video-playlist__video_1-0-settings-submenu-quality"
                        className="jw-reset jw-settings-submenu jw-settings-submenu-quality"
                        aria-expanded="false"
                        aria-label="Quality"
                      >
                        <div className="jw-reset jw-settings-submenu-items">
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item jw-settings-item-active"
                            aria-label="Auto"
                            dir="auto"
                          >
                            Auto
                            <span className="jw-reset jw-auto-label">
                              1080p
                            </span>
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="1080p"
                            dir="auto"
                          >
                            1080p
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="720p"
                            dir="auto"
                          >
                            720p
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="406p"
                            dir="auto"
                          >
                            406p
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="270p"
                            dir="auto"
                          >
                            270p
                          </button>
                          <button
                            type="button"
                            className="jw-reset-text jw-settings-content-item"
                            aria-label="180p"
                            dir="auto"
                          >
                            180p
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="jw-controlbar jw-reset">
                      <div
                        className="jw-slider-time jw-chapter-slider-time jw-background-color jw-reset jw-slider-horizontal jw-reset"
                        aria-valuetext="0 seconds of 2 minutes, 58 seconds"
                        aria-valuenow={0}
                        tabIndex={0}
                        aria-label="Seek"
                        aria-valuemin={0}
                        aria-valuemax="178.25"
                      >
                        <div className="jw-slider-container jw-reset">
                          <div className="jw-reset jw-old-rail">
                            <div
                              className="jw-timesegment jw-reset"
                              aria-hidden="true"
                              style={{ flexGrow: 178 }}
                            >
                              <div className="jw-timesegment-resetter jw-reset">
                                <div className="jw-timesegment-container jw-reset">
                                  <div className="jw-timesegment-bar jw-rail jw-timesegment-background jw-reset" />
                                  <div
                                    className="jw-timesegment-bar jw-buffer jw-timesegment-buffered jw-reset"
                                    style={{ width: "2.25214%" }}
                                  />
                                  <div
                                    className="jw-timesegment-bar jw-progress jw-timesegment-progress jw-reset"
                                    style={{ width: "0%" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="jw-reset jw-old-buffer"
                            style={{ width: "2.2536%" }}
                          />
                          <div
                            className="jw-reset jw-old-progress"
                            style={{ width: "0%" }}
                          />
                          <div
                            className="jw-knob jw-reset"
                            style={{ left: "0%" }}
                          />
                          <div className="jw-icon jw-icon-tooltip jw-tooltip-time jw-button-color jw-reset">
                            <div className="jw-overlay jw-reset">
                              <div className="jw-time-tip jw-reset">
                                <div
                                  className="jw-time-thumb jw-reset"
                                  style={{
                                    width: 120,
                                    height: 67,
                                    margin: "0px auto",
                                    backgroundImage:
                                      'url("https://cdn.jwplayer.com/strips/Jn8mpBFh-120.jpg")',
                                    backgroundPosition: "0px 0px",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                />
                                <span
                                  className="jw-time-chapter jw-text jw-reset"
                                  style={{ display: "none" }}
                                />
                                <span className="jw-time-time jw-text jw-reset" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="jw-reset jw-button-container">
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-playback"
                          tabIndex={0}
                          aria-label="Play"
                          style={{}}
                        >
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-play"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M62.8,199.5c-1,0.8-2.4,0.6-3.3-0.4c-0.4-0.5-0.6-1.1-0.5-1.8V42.6c-0.2-1.3,0.7-2.4,1.9-2.6c0.7-0.1,1.3,0.1,1.9,0.4l154.7,77.7c2.1,1.1,2.1,2.8,0,3.8L62.8,199.5z" />
                          </svg> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-pause"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M100,194.9c0.2,2.6-1.8,4.8-4.4,5c-0.2,0-0.4,0-0.6,0H65c-2.6,0.2-4.8-1.8-5-4.4c0-0.2,0-0.4,0-0.6V45c-0.2-2.6,1.8-4.8,4.4-5c0.2,0,0.4,0,0.6,0h30c2.6-0.2,4.8,1.8,5,4.4c0,0.2,0,0.4,0,0.6V194.9z M180,45.1c0.2-2.6-1.8-4.8-4.4-5c-0.2,0-0.4,0-0.6,0h-30c-2.6-0.2-4.8,1.8-5,4.4c0,0.2,0,0.4,0,0.6V195c-0.2,2.6,1.8,4.8,4.4,5c0.2,0,0.4,0,0.6,0h30c2.6,0.2,4.8-1.8,5-4.4c0-0.2,0-0.4,0-0.6V45.1z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-stop"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M190,185c0.2,2.6-1.8,4.8-4.4,5c-0.2,0-0.4,0-0.6,0H55c-2.6,0.2-4.8-1.8-5-4.4c0-0.2,0-0.4,0-0.6V55c-0.2-2.6,1.8-4.8,4.4-5c0.2,0,0.4,0,0.6,0h130c2.6-0.2,4.8,1.8,5,4.4c0,0.2,0,0.4,0,0.6V185z" />
                          </svg>
                          <div
                            className="jw-reset-text jw-tooltip jw-tooltip-play"
                            dir="auto"
                          >
                            <div className="jw-text" />
                          </div>
                        </div>
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-rewind"
                          tabIndex={0}
                          aria-label="Rewind 10 Seconds"
                          style={{}}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-rewind"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M113.2,131.078a21.589,21.589,0,0,0-17.7-10.6,21.589,21.589,0,0,0-17.7,10.6,44.769,44.769,0,0,0,0,46.3,21.589,21.589,0,0,0,17.7,10.6,21.589,21.589,0,0,0,17.7-10.6,44.769,44.769,0,0,0,0-46.3Zm-17.7,47.2c-7.8,0-14.4-11-14.4-24.1s6.6-24.1,14.4-24.1,14.4,11,14.4,24.1S103.4,178.278,95.5,178.278Zm-43.4,9.7v-51l-4.8,4.8-6.8-6.8,13-13a4.8,4.8,0,0,1,8.2,3.4v62.7l-9.6-.1Zm162-130.2v125.3a4.867,4.867,0,0,1-4.8,4.8H146.6v-19.3h48.2v-96.4H79.1v19.3c0,5.3-3.6,7.2-8,4.3l-41.8-27.9a6.013,6.013,0,0,1-2.7-8,5.887,5.887,0,0,1,2.7-2.7l41.8-27.9c4.4-2.9,8-1,8,4.3v19.3H209.2A4.974,4.974,0,0,1,214.1,57.778Z" />
                          </svg>
                          <div
                            className="jw-reset-text jw-tooltip jw-tooltip-rewind"
                            dir="auto"
                          >
                            <div className="jw-text" />
                          </div>
                        </div>
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-next"
                          tabIndex={0}
                          aria-label="Next"
                          dir="auto"
                          style={{}}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-next"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M165,60v53.3L59.2,42.8C56.9,41.3,55,42.3,55,45v150c0,2.7,1.9,3.8,4.2,2.2L165,126.6v53.3h20v-120L165,60L165,60z" />
                          </svg>
                          <div
                            className="jw-reset-text jw-tooltip jw-tooltip-next"
                            dir="auto"
                          >
                            <div className="jw-text" />
                          </div>
                        </div>
                        <div
                          aria-label="Mute button"
                          tabIndex={0}
                          className="jw-icon jw-icon-tooltip jw-icon-volume jw-button-color jw-reset jw-full"
                        >
                          <div
                            className="jw-overlay jw-reset"
                            tabIndex={0}
                            aria-label="Volume"
                            aria-orientation="vertical"
                            aria-valuemin={0}
                            aria-valuemax={100}
                            aria-valuenow={90}
                            aria-valuetext="Volume 90%"
                          >
                            <div
                              className="jw-slider-volume jw-volume-tip jw-reset jw-slider-vertical"
                              aria-hidden="true"
                            >
                              <div className="jw-slider-container jw-reset">
                                <div className="jw-rail jw-reset" />
                                <div className="jw-buffer jw-reset" />
                                <div
                                  className="jw-progress jw-reset"
                                  style={{ height: "90%" }}
                                />
                                <div
                                  className="jw-knob jw-reset"
                                  style={{ bottom: "90%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-volume-0"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M116.4,42.8v154.5c0,2.8-1.7,3.6-3.8,1.7l-54.1-48.1H28.9c-2.8,0-5.2-2.3-5.2-5.2V94.2c0-2.8,2.3-5.2,5.2-5.2h29.6l54.1-48.1C114.6,39.1,116.4,39.9,116.4,42.8z M212.3,96.4l-14.6-14.6l-23.6,23.6l-23.6-23.6l-14.6,14.6l23.6,23.6l-23.6,23.6l14.6,14.6l23.6-23.6l23.6,23.6l14.6-14.6L188.7,120L212.3,96.4z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-volume-50"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M116.4,42.8v154.5c0,2.8-1.7,3.6-3.8,1.7l-54.1-48.1H28.9c-2.8,0-5.2-2.3-5.2-5.2V94.2c0-2.8,2.3-5.2,5.2-5.2h29.6l54.1-48.1C114.7,39.1,116.4,39.9,116.4,42.8z M178.2,120c0-22.7-18.5-41.2-41.2-41.2v20.6c11.4,0,20.6,9.2,20.6,20.6c0,11.4-9.2,20.6-20.6,20.6v20.6C159.8,161.2,178.2,142.7,178.2,120z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-volume-100"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M116.5,42.8v154.4c0,2.8-1.7,3.6-3.8,1.7l-54.1-48H29c-2.8,0-5.2-2.3-5.2-5.2V94.3c0-2.8,2.3-5.2,5.2-5.2h29.6l54.1-48C114.8,39.2,116.5,39.9,116.5,42.8z" />
                            <path d="M136.2,160v-20c11.1,0,20-8.9,20-20s-8.9-20-20-20V80c22.1,0,40,17.9,40,40S158.3,160,136.2,160z" />
                            <path d="M216.2,120c0-44.2-35.8-80-80-80v20c33.1,0,60,26.9,60,60s-26.9,60-60,60v20C180.4,199.9,216.1,164.1,216.2,120z" />
                          </svg>
                        </div>
                        <div
                          className="jw-horizontal-volume-container"
                          tabIndex={0}
                          aria-label="Volume"
                          aria-orientation="horizontal"
                          aria-valuemin={0}
                          aria-valuemax={100}
                          aria-valuenow={90}
                          aria-valuetext="Volume 90%"
                        >
                          <div
                            className="jw-slider-volume jw-reset jw-slider-horizontal"
                            aria-hidden="true"
                          >
                            <div className="jw-slider-container jw-reset">
                              <div className="jw-rail jw-reset" />
                              <div className="jw-buffer jw-reset" />
                              <div
                                className="jw-progress jw-reset"
                                style={{ width: "90%" }}
                              />
                              <div
                                className="jw-knob jw-reset"
                                style={{ left: "90%" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-fullscreen jw-fullscreen-ima"
                          tabIndex={0}
                          aria-label="Fullscreen"
                          style={{}}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-fullscreen-off"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M109.2,134.9l-8.4,50.1c-0.4,2.7-2.4,3.3-4.4,1.4L82,172l-27.9,27.9l-14.2-14.2l27.9-27.9l-14.4-14.4c-1.9-1.9-1.3-3.9,1.4-4.4l50.1-8.4c1.8-0.5,3.6,0.6,4.1,2.4C109.4,133.7,109.4,134.3,109.2,134.9L109.2,134.9z M172.1,82.1L200,54.2L185.8,40l-27.9,27.9l-14.4-14.4c-1.9-1.9-3.9-1.3-4.4,1.4l-8.4,50.1c-0.5,1.8,0.6,3.6,2.4,4.1c0.5,0.2,1.2,0.2,1.7,0l50.1-8.4c2.7-0.4,3.3-2.4,1.4-4.4L172.1,82.1z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-fullscreen-on"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M96.3,186.1c1.9,1.9,1.3,4-1.4,4.4l-50.6,8.4c-1.8,0.5-3.7-0.6-4.2-2.4c-0.2-0.6-0.2-1.2,0-1.7l8.4-50.6c0.4-2.7,2.4-3.4,4.4-1.4l14.5,14.5l28.2-28.2l14.3,14.3l-28.2,28.2L96.3,186.1z M195.8,39.1l-50.6,8.4c-2.7,0.4-3.4,2.4-1.4,4.4l14.5,14.5l-28.2,28.2l14.3,14.3l28.2-28.2l14.5,14.5c1.9,1.9,4,1.3,4.4-1.4l8.4-50.6c0.5-1.8-0.6-3.6-2.4-4.2C197,39,196.4,39,195.8,39.1L195.8,39.1z" />
                          </svg>
                          <div
                            className="jw-reset-text jw-tooltip jw-tooltip-undefined"
                            dir="auto"
                          >
                            <div className="jw-text" />
                          </div>
                        </div>
                        <span
                          className="jw-text jw-reset-text jw-text-alt"
                          dir="auto"
                        />
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset jw-text-live"
                          tabIndex={0}
                          aria-label="Live"
                          style={{ display: "none" }}
                        >
                          Live
                        </div>
                        <div className="jw-icon jw-icon-inline jw-text jw-reset jw-text-elapsed">
                          00:00
                        </div>
                        <div className="jw-icon jw-icon-inline jw-text jw-reset jw-text-countdown">
                          02:58
                        </div>
                        <div className="jw-icon jw-icon-inline jw-text jw-reset jw-text-duration">
                          02:58
                        </div>
                        <div className="jw-reset jw-spacer" />
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset cc-icon cc-icon--active"
                          button="cc-icon"
                          tabIndex={0}
                          aria-label="Closed Captions"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            className="jw-svg-icon jw-svg-icon-cc-on"
                          >
                            <use xlinkHref="#jw-player-cc" />
                          </svg>
                          <div
                            className="jw-reset-text jw-tooltip jw-tooltip-cc-icon"
                            dir="auto"
                          >
                            <div className="jw-text" />
                          </div>
                        </div>
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-cc jw-settings-submenu-button"
                          tabIndex={0}
                          aria-label="Closed Captions"
                          aria-controls="jw-video-playlist__video_1-0-settings-submenu-captions"
                          style={{}}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-cc-on"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M215,40H25c-2.7,0-5,2.2-5,5v150c0,2.7,2.2,5,5,5h190c2.7,0,5-2.2,5-5V45C220,42.2,217.8,40,215,40z M108.1,137.7c0.7-0.7,1.5-1.5,2.4-2.3l6.6,7.8c-2.2,2.4-5,4.4-8,5.8c-8,3.5-17.3,2.4-24.3-2.9c-3.9-3.6-5.9-8.7-5.5-14v-25.6c0-2.7,0.5-5.3,1.5-7.8c0.9-2.2,2.4-4.3,4.2-5.9c5.7-4.5,13.2-6.2,20.3-4.6c3.3,0.5,6.3,2,8.7,4.3c1.3,1.3,2.5,2.6,3.5,4.2l-7.1,6.9c-2.4-3.7-6.5-5.9-10.9-5.9c-2.4-0.2-4.8,0.7-6.6,2.3c-1.7,1.7-2.5,4.1-2.4,6.5v25.6C90.4,141.7,102,143.5,108.1,137.7z M152.9,137.7c0.7-0.7,1.5-1.5,2.4-2.3l6.6,7.8c-2.2,2.4-5,4.4-8,5.8c-8,3.5-17.3,2.4-24.3-2.9c-3.9-3.6-5.9-8.7-5.5-14v-25.6c0-2.7,0.5-5.3,1.5-7.8c0.9-2.2,2.4-4.3,4.2-5.9c5.7-4.5,13.2-6.2,20.3-4.6c3.3,0.5,6.3,2,8.7,4.3c1.3,1.3,2.5,2.6,3.5,4.2l-7.1,6.9c-2.4-3.7-6.5-5.9-10.9-5.9c-2.4-0.2-4.8,0.7-6.6,2.3c-1.7,1.7-2.5,4.1-2.4,6.5v25.6C135.2,141.7,146.8,143.5,152.9,137.7z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-cc-off"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M99.4,97.8c-2.4-0.2-4.8,0.7-6.6,2.3c-1.7,1.7-2.5,4.1-2.4,6.5v25.6c0,9.6,11.6,11.4,17.7,5.5c0.7-0.7,1.5-1.5,2.4-2.3l6.6,7.8c-2.2,2.4-5,4.4-8,5.8c-8,3.5-17.3,2.4-24.3-2.9c-3.9-3.6-5.9-8.7-5.5-14v-25.6c0-2.7,0.5-5.3,1.5-7.8c0.9-2.2,2.4-4.3,4.2-5.9c5.7-4.5,13.2-6.2,20.3-4.6c3.3,0.5,6.3,2,8.7,4.3c1.3,1.3,2.5,2.6,3.5,4.2l-7.1,6.9C107.9,100,103.8,97.8,99.4,97.8z M144.1,97.8c-2.4-0.2-4.8,0.7-6.6,2.3c-1.7,1.7-2.5,4.1-2.4,6.5v25.6c0,9.6,11.6,11.4,17.7,5.5c0.7-0.7,1.5-1.5,2.4-2.3l6.6,7.8c-2.2,2.4-5,4.4-8,5.8c-8,3.5-17.3,2.4-24.3-2.9c-3.9-3.6-5.9-8.7-5.5-14v-25.6c0-2.7,0.5-5.3,1.5-7.8c0.9-2.2,2.4-4.3,4.2-5.9c5.7-4.5,13.2-6.2,20.3-4.6c3.3,0.5,6.3,2,8.7,4.3c1.3,1.3,2.5,2.6,3.5,4.2l-7.1,6.9C152.6,100,148.5,97.8,144.1,97.8L144.1,97.8z M200,60v120H40V60H200 M215,40H25c-2.7,0-5,2.2-5,5v150c0,2.7,2.2,5,5,5h190c2.7,0,5-2.2,5-5V45C220,42.2,217.8,40,215,40z" />
                          </svg>
                          <div
                            className="jw-reset-text jw-tooltip jw-tooltip-captions"
                            dir="auto"
                          >
                            <div className="jw-text" />
                          </div>
                        </div>
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-settings jw-settings-submenu-button"
                          tabIndex={0}
                          aria-label="Settings"
                          aria-controls="jw-video-playlist__video_1-0-settings-menu"
                          aria-expanded="false"
                          style={{}}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-settings"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M204,145l-25-14c0.8-3.6,1.2-7.3,1-11c0.2-3.7-0.2-7.4-1-11l25-14c2.2-1.6,3.1-4.5,2-7l-16-26c-1.2-2.1-3.8-2.9-6-2l-25,14c-6-4.2-12.3-7.9-19-11V35c0.2-2.6-1.8-4.8-4.4-5c-0.2,0-0.4,0-0.6,0h-30c-2.6-0.2-4.8,1.8-5,4.4c0,0.2,0,0.4,0,0.6v28c-6.7,3.1-13,6.7-19,11L56,60c-2.2-0.9-4.8-0.1-6,2L35,88c-1.6,2.2-1.3,5.3,0.9,6.9c0,0,0.1,0,0.1,0.1l25,14c-0.8,3.6-1.2,7.3-1,11c-0.2,3.7,0.2,7.4,1,11l-25,14c-2.2,1.6-3.1,4.5-2,7l16,26c1.2,2.1,3.8,2.9,6,2l25-14c5.7,4.6,12.2,8.3,19,11v28c-0.2,2.6,1.8,4.8,4.4,5c0.2,0,0.4,0,0.6,0h30c2.6,0.2,4.8-1.8,5-4.4c0-0.2,0-0.4,0-0.6v-28c7-2.3,13.5-6,19-11l25,14c2.5,1.3,5.6,0.4,7-2l15-26C206.7,149.4,206,146.7,204,145z M120,149.9c-16.5,0-30-13.4-30-30s13.4-30,30-30s30,13.4,30,30c0.3,16.3-12.6,29.7-28.9,30C120.7,149.9,120.4,149.9,120,149.9z" />
                          </svg>
                          <div
                            className="jw-reset-text jw-tooltip jw-tooltip-settings"
                            dir="auto"
                          >
                            <div className="jw-text" />
                          </div>
                        </div>
                        <div
                          className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-fullscreen"
                          tabIndex={0}
                          aria-label="Fullscreen"
                          style={{}}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-fullscreen-off"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M109.2,134.9l-8.4,50.1c-0.4,2.7-2.4,3.3-4.4,1.4L82,172l-27.9,27.9l-14.2-14.2l27.9-27.9l-14.4-14.4c-1.9-1.9-1.3-3.9,1.4-4.4l50.1-8.4c1.8-0.5,3.6,0.6,4.1,2.4C109.4,133.7,109.4,134.3,109.2,134.9L109.2,134.9z M172.1,82.1L200,54.2L185.8,40l-27.9,27.9l-14.4-14.4c-1.9-1.9-3.9-1.3-4.4,1.4l-8.4,50.1c-0.5,1.8,0.6,3.6,2.4,4.1c0.5,0.2,1.2,0.2,1.7,0l50.1-8.4c2.7-0.4,3.3-2.4,1.4-4.4L172.1,82.1z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="jw-svg-icon jw-svg-icon-fullscreen-on"
                            viewBox="0 0 240 240"
                            focusable="false"
                          >
                            <path d="M96.3,186.1c1.9,1.9,1.3,4-1.4,4.4l-50.6,8.4c-1.8,0.5-3.7-0.6-4.2-2.4c-0.2-0.6-0.2-1.2,0-1.7l8.4-50.6c0.4-2.7,2.4-3.4,4.4-1.4l14.5,14.5l28.2-28.2l14.3,14.3l-28.2,28.2L96.3,186.1z M195.8,39.1l-50.6,8.4c-2.7,0.4-3.4,2.4-1.4,4.4l14.5,14.5l-28.2,28.2l14.3,14.3l28.2-28.2l14.5,14.5c1.9,1.9,4,1.3,4.4-1.4l8.4-50.6c0.5-1.8-0.6-3.6-2.4-4.2C197,39,196.4,39,195.8,39.1L195.8,39.1z" />
                          </svg>
                          <div
                            className="jw-reset-text jw-tooltip jw-tooltip-fullscreen"
                            dir="auto"
                          >
                            <div className="jw-text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="afs_ads ad-placement"
                  style={{
                    width: 1,
                    height: 1,
                    position: "absolute",
                    background: "transparent",
                  }}
                >
                  &nbsp;
                </div>
              </div>{" "}
            </div>
            <div className="video-playlist__current-info" data-headline="">
              <p className="video-playlist__current-title">
                How to Build a DIY Fire Pit
              </p>
              <p className="video-playlist__current-description">
                Enjoy your backyard well into the evening with the help of a
                fire pit. Learn how to DIY your own stone fire pit—it's easier
                than you think.
              </p>
              <div className="video-playlist__current-link-wrapper">
                <a
                  href="https://www.thespruce.com/how-to-build-a-firepit-4160705"
                  rel="nocaes"
                  className="video-playlist__current-link"
                >
                  Read the Article
                </a>
              </div>
            </div>
          </div>
          <div className="video-playlist__playlist">
            <p className="video-playlist__playlist-headline">Watch More</p>
            <div
              id="video-playlist__playlist-shelf"
              className="video-playlist__playlist-shelf jw-related-shelf-container jw-shelf-widget jw-shelf-items-5 jw-breakpoint-5"
              style={{ position: "relative" }}
            >
              <div
                style={{
                  opacity: 0,
                  visibility: "hidden",
                  overflow: "hidden",
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    overflow: "auto",
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div style={{ height: 1, width: 1221 }} />
                </div>
                <div
                  className="jw-contract-trigger"
                  style={{
                    overflow: "auto",
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <div className="jw-related-shelf-contents">
                <div className="jw-related-shelf">
                  <div
                    className="jw-icon jw-related-control jw-related-control-left jw-off"
                    aria-label="Previous"
                    tabIndex={-1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="jw-svg-icon jw-svg-icon-arrow-left"
                      viewBox="0 0 240 240"
                      focusable="false"
                    >
                      <path d="M55.4,104.4c-1.1,1.1-2.2,2.3-3.1,3.6c-6.9,9.9-4.4,23.5,5.5,30.4L159.7,240l33.9-33.9l-84.9-84.9l84.9-84.9L157.3,0L55.4,104.4L55.4,104.4z" />
                    </svg>
                  </div>
                  <div className="jw-related-shelf-list">
                    <div className="jw-related-shelf-items jw-related-shelf-start">
                      <div
                        className="jw-related-shelf-item jw-related-item-visible"
                        tabIndex={0}
                        aria-label="Video - How to Build a DIY Fire Pit"
                        data-jw-index={0}
                      >
                        <div
                          className="jw-related-shelf-item-image jw-related-loaded"
                          style={{
                            backgroundImage:
                              'url("https://cdn.jwplayer.com/v2/media/Jn8mpBFh/poster.jpg?width=320")',
                          }}
                        ></div>
                        <div className="jw-related-shelf-item-container">
                          <div className="jw-related-shelf-item-contents">
                            <div
                              className="jw-related-shelf-item-title"
                              dir="auto"
                            >
                              How to Build a DIY Fire Pit
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="jw-related-shelf-item jw-related-item-visible"
                        tabIndex={0}
                        aria-label="Video - 5 Great Home Bar Ideas"
                        data-jw-index={1}
                      >
                        <div
                          className="jw-related-shelf-item-image jw-related-loaded"
                          style={{
                            backgroundImage:
                              'url("https://cdn.jwplayer.com/v2/media/t6NqzHY8/poster.jpg?width=320")',
                          }}
                        ></div>
                        <div className="jw-related-shelf-item-container">
                          <div className="jw-related-shelf-item-contents">
                            <div
                              className="jw-related-shelf-item-title"
                              dir="auto"
                            >
                              5 Great Home Bar Ideas
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="jw-related-shelf-item jw-related-item-visible"
                        tabIndex={0}
                        aria-label="Video - Clean These 9 Places in Your Bathroom That Are Especially Dirty"
                        data-jw-index={2}
                      >
                        <div
                          className="jw-related-shelf-item-image jw-related-loaded"
                          style={{
                            backgroundImage:
                              'url("https://cdn.jwplayer.com/v2/media/IYnqIo4h/poster.jpg?width=320")',
                          }}
                        ></div>
                        <div className="jw-related-shelf-item-container">
                          <div className="jw-related-shelf-item-contents">
                            <div
                              className="jw-related-shelf-item-title"
                              dir="auto"
                            >
                              Clean These 9 Places in Your Bathroom That Are
                              Especially Dirty
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="jw-related-shelf-item jw-related-item-visible"
                        tabIndex={0}
                        aria-label="Video - How to Freshen Your Closet with Baking Soda"
                        data-jw-index={3}
                      >
                        <div
                          className="jw-related-shelf-item-image jw-related-loaded"
                          style={{
                            backgroundImage:
                              'url("https://cdn.jwplayer.com/v2/media/Hnwk7Car/poster.jpg?width=320")',
                          }}
                        ></div>
                        <div className="jw-related-shelf-item-container">
                          <div className="jw-related-shelf-item-contents">
                            <div
                              className="jw-related-shelf-item-title"
                              dir="auto"
                            >
                              How to Freshen Your Closet with Baking Soda
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="jw-related-shelf-item jw-related-item-visible"
                        tabIndex={0}
                        aria-label="Video - How to Design Your Bedroom Like a 5-Star Hotel Room"
                        data-jw-index={4}
                      >
                        <div
                          className="jw-related-shelf-item-image jw-related-loaded"
                          style={{
                            backgroundImage:
                              'url("https://cdn.jwplayer.com/v2/media/AcFplk4V/poster.jpg?width=320")',
                          }}
                        ></div>
                        <div className="jw-related-shelf-item-container">
                          <div className="jw-related-shelf-item-contents">
                            <div
                              className="jw-related-shelf-item-title"
                              dir="auto"
                            >
                              How to Design Your Bedroom Like a 5-Star Hotel
                              Room
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="jw-icon jw-related-control jw-related-control-right"
                    aria-label="Next"
                    tabIndex={0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="jw-svg-icon jw-svg-icon-arrow-right"
                      viewBox="0 0 240 240"
                      focusable="false"
                    >
                      <path d="M183.6,104.4L81.8,0L45.4,36.3l84.9,84.9l-84.9,84.9L79.3,240l101.9-101.7c9.9-6.9,12.4-20.4,5.5-30.4C185.8,106.7,184.8,105.4,183.6,104.4L183.6,104.4z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
