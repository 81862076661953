import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer
      id="footer_1-0"
      className="comp footer mntl-footer"
      role="contentinfo"
      data-tracking-container="true"
    >
      {" "}
      <div className="footer__wrapper">
        <div className="footer__inner l-container-med">
          <Link id="logo_1-0" className="comp logo thespruce" to="/">
            {" "}
            <img className="logo__text" alt="" src="/head/foot1.png" />
          </Link>
          <div id="newsletter-widget_2-0" className="comp newsletter-widget">
            <div
              id="newsletter__heading_1-0"
              className="comp newsletter__heading mntl-text-block"
            >
              Email:super@thespruce.click
              <br /> Name:Big Horn Basin Healthcare Coalition, Inc.
              <br />
              Address:637 Tri Power Ct Cody, WY 82414
            </div>
            <a
              href="#"
              rel="nocaes"
              data-a11y-dialog-show="newsletter-dialog-footer"
              id="newsletter__button_3-0"
              role="button"
              className="newsletter__button newsletter-dialog-footer-link newsletter-dialog-link dialog-link mntl-text-link "
              data-tracking-container="true"
            >
              <span className="link__wrapper">Subscribe</span>
            </a>
          </div>
          <ul id="horizontal-nav_1-0" className="comp horizontal-nav">
            {" "}
            <li className="horizontal-nav__item ">
              <Link
                to="/about"
                className="horizontal-nav__link"
                onClick={toTop}
              >
                {" "}
                About Us
              </Link>{" "}
            </li>
            <li className="horizontal-nav__item ">
              <Link
                to="/terms"
                className="horizontal-nav__link"
                onClick={toTop}
              >
                {" "}
                Terms of Service
              </Link>{" "}
            </li>
            <li className="horizontal-nav__item ">
              <Link
                to="/privacy"
                className="horizontal-nav__link"
                onClick={toTop}
              >
                {" "}
                Privacy Policy
              </Link>{" "}
            </li>
            <li className="horizontal-nav__item ">
              <Link
                to="/contact"
                className="horizontal-nav__link"
                onClick={toTop}
              >
                {" "}
                Contact
              </Link>{" "}
            </li>
          </ul>
          <a
            id="truste-badge_1-0"
            className="comp truste-badge mntl-truste-badge mntl-block"
            href="//privacy.truste.com/privacy-seal/validation?rid=428db2d1-80a7-49ef-8cc9-c8c451fd55b2"
            data-tracking-container="true"
            aria-label="Access TRUSTe's Enterprise Privacy Certification program"
            rel="noopener"
          >
            <img
              id="badge-image_1-0"
              className="comp badge-image mntl-block lazyloadwait lazyloaded"
              data-src="//privacy-policy.truste.com/privacy-seal/seal?rid=428db2d1-80a7-49ef-8cc9-c8c451fd55b2"
              alt="TRUSTe"
              width={142}
              height={49}
              src="//privacy-policy.truste.com/privacy-seal/seal?rid=428db2d1-80a7-49ef-8cc9-c8c451fd55b2"
            />
          </a>{" "}
        </div>
        <div className="loc bottom-content footer__bottom">
          <div
            id="mntl-dotdash-universal-nav_1-0"
            className="comp mntl-dotdash-universal-nav l-container-med"
            data-tracking-container="true"
          >
            {" "}
            <div className="mntl-dotdash-universal-nav__content">
              <img
                alt=""
                src="/head/foot3.png"
                className="mntl-dotdash-universal-nav__logo"
              />
              <div className="mntl-dotdash-universal-nav__wrapper">
                <div className="mntl-dotdash-universal-nav__text">
                  The Spruce is part of the{" "}
                  <a
                    href="https://www.dotdashmeredith.com"
                    rel="noopener nofollow nocaes"
                    className="mntl-dotdash-universal-nav__text--link"
                  >
                    Dotdash Meredith
                  </a>
                  &nbsp;publishing&nbsp;family.
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </footer>
  );
}

export default Footer;
