import "../css/header.css";
import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="head_top">
        <Link to="/">
          <img alt="" src="/head/head.png" />
        </Link>
      </div>
      <div className="head_mid">
        <ul>
          <li>
            <Link to="/" onClick={toTop}>
              <span>Home</span>
            </Link>
          </li>{" "}
          <li>
            <Link to="/decore" onClick={toTop}>
              <span>DECOR</span>
            </Link>
          </li>
          <li>
            <Link to="/garden" onClick={toTop}>
              <span>garden</span>
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={toTop}>
              <span>about</span>
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={toTop}>
              <span>contact</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="head_bot">
        <Link to="/" onClick={toTop}>
          <span>Cyber Monday is Here! The Best Deals—Up to 80% Off</span>
        </Link>
      </div>
    </div>
  );
}

export default Header;
